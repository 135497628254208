import React, { useEffect } from 'react'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { useLogin } from '../../utils/auth.utils'
import { Layout, Space, Button, Row, Divider } from 'antd'
import { Letter } from '../../components/application/letter'
import { letterRequest } from '../../services/reference.service'

const { Header, Footer, Sider, Content } = Layout

const LetterScreen = () => {
  const [reference, setReference] = React.useState(null)
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()

  const uuid = query.get('uuid')

  const getReference = async () => {
    const record = await letterRequest(uuid)
    setReference(record.data)
  }
  useEffect(() => {
    getReference()
  }, [uuid])

  return (
    <Layout>
      <Letter reference={reference} max={250} />
    </Layout>
  )
}

export default LetterScreen
