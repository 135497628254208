import { APIResponse } from "../models/api.model";
import { Reference } from "../models/reference.model";
import { get, put} from "./utils.service";

export const letterRequest = async (uuid): Promise<any> => {
  const res = await get(`/applications/references/letterRequest/${uuid}`);
  return await res.json();
};

export const submit = async (record): Promise<Reference> => {
  const res = await put(`/applications/references/submit`, record);
  return await res.json();
};
