const colors = {
  primary: "#ed422eff",
  secondary: "#6d2b93",
  success: "#28A745",
  danger: "#DC3545",
  warning: "#FFC107",
  info: "#17A2B8",
  dark: "#0B0111",
  light: "#F8F9FA",
  transparent: "transparent"
};

export default colors;