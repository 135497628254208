import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Form, Input, Select, Radio, message, Col, Row, DatePicker } from 'antd'

import { findById, update } from '../../services/applications.service'
import { findAll } from '../../services/institutions.service'
import { Main } from '../../components/application/main'
import { Essay } from '../../components/application/essay'
import { References } from '../../components/application/references'
import { defaultRecord } from '../../contants/test.record'
import moment from 'moment'
const ApplicationEdit = () => {
  const params = useParams()
  const id = params['id']
  const [institutions, setInstitutions] = React.useState(null)
  const [application, setApplication] = React.useState(null)
  const [user, setUser] = React.useState(null)
  const [form] = Form.useForm()

  const getInstitutions = async () => {
    const u = await findAll()
    setInstitutions(
      u.data.map(x => {
        return { label: x.name, value: x.id }
      }),
    )
  }

  const getApplication = async id => {
    const record = await findById(id)
    const app = record.data
    app.institution = app.institution?.id
    return setApplication(record.data)
  }

  useEffect(() => {
    getInstitutions()
    if (id !== undefined) {
      getApplication(id)
    }
  }, [id])

  const onFinish = async (values: any) => {
    const res = await update(form.getFieldsValue(true))
    setUser(res.data)
    message.info('User updated')
  }

  return (
    <>
      {application && (
        <Form
          form={form}
          name="User"
          onFinish={onFinish}
          initialValues={application}
          scrollToFirstError
          labelCol={{
            span: 4,
            offset: 2,
          }}
          wrapperCol={{
            span: 12,
          }}
          layout="horizontal"
        >
          <Main form={form} />
          {!application.renewal && (
            <>
              <Form.Item label="Essay">
                <Essay value={application.essay || ''} />
              </Form.Item>
              <Form.Item label="Letters of Support">
                <References />
              </Form.Item>
            </>
          )}
          <Button type="primary" style={{ float: 'right' }} htmlType="submit">
            Save
          </Button>
        </Form>
      )}
    </>
  )
}

export default ApplicationEdit
