import React, { useState } from 'react'
import { Form, Button, Space, Select, InputNumber, message, Radio } from 'antd'
import 'antd/dist/antd.css'
import { reviewStatusOptions, classOptions, yesNoOptions } from '../../contants/options'
import { upsert } from '../../services/reviews.service'
import TextArea from 'antd/es/input/TextArea'
import moment from 'moment'

export const Reviews = props => {
  const [form] = Form.useForm()
  const [status, setStatus] = React.useState('')
  const [classValue, setClassValue] = React.useState('')
  const [isRequired, setIsRequired] = useState(true);

  const onFinish = async () => {
    const data = form.getFieldsValue()
    data.application = props.application
    data.id = props.review.id
    const res = await upsert(data)
    await message.info('Review Saved')
    props.refreshApp(res)
    props.close()
  }

  const handleClass = e => {
    setClassValue(e)
  }

  const statusChange = e => {
    setStatus(e)
    setIsRequired(['awarded', 'alternate'].includes(e))
  }

  return (
    <Form
      form={form}
      name="reviews"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={props.review}
      labelCol={{
        span: 10,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
    >
      {props.review.submitted && (
        <Form.Item name="submitted" label="Date Reviewed">
          {moment(props.review.submitted).format('LL')}
        </Form.Item>
      )}

      <Form.Item
        name="status"
        label="Status"
        rules={[
          {
            type: 'string',
            required: true,
            message: 'Please enter a status',
          },
        ]}
      >
        <Select options={reviewStatusOptions} onChange={statusChange} />
      </Form.Item>

      <Form.Item
        name="class"
        label={'Class'}
        rules={[
          {
            required: isRequired,
            message: 'Please select their class',
          },
        ]}
      >
        <Radio.Group
          options={classOptions}
          onChange={handleClass}
          value={classValue}
          defaultValue={props.review.class}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>
      <Form.Item
        name="gpa"
        label="Verified GPA"
        rules={[
          {
            required: true,
            message: 'Please enter official GPA!',
          },
        ]}
      >
        <InputNumber min={0} precision={3} step={0.1} max={5} />
      </Form.Item>

      <Form.Item
        name="amount_requested"
        label="Total Financial Aid Needed"
        rules={[
          {
            type: 'number',
            required: true,
            message: 'Please enter amount requested!',
          },
        ]}
      >
        <InputNumber
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>

      <Form.Item
        name="amount_awarded"
        label="Total Financial Aid Awarded "
        rules={[
          {
            type: 'number',
            required: isRequired,
            message: 'Please enter amount awarded!',
          },
        ]}
      >
        <InputNumber
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>

      <Form.Item
        name="amount_actual"
        label="Total Carver Scholars Award"
        rules={[
          {
            type: 'number',
            required: isRequired,
            message: 'Please enter amount actually given!',
          },
        ]}
      >
        <InputNumber
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>
      <Form.Item name="notes" label="Review Comments">
        <TextArea rows={10} />
      </Form.Item>

      <Button type="primary" style={{ float: 'right' }} htmlType="submit">
        Save Review
      </Button>
    </Form>
  )
}
