import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Breadcrumb, Menu, Table, Button, Row, Col, Tag } from 'antd'
import { store } from '../../store/configure.store'
import '../../App.css'
import MeSlice from '../../store/slices/me.slice'
import { refreshToken } from '../../services/auth.service'
import { Redirect, useHistory } from 'react-router'
import { logout, useLogin } from '../../utils/auth.utils'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import {
  LogoutOutlined,
  HomeOutlined,
  UserOutlined,
  TableOutlined,
  MenuOutlined,
  DatabaseOutlined,
  ContainerOutlined,
  BuildOutlined,
  DollarCircleOutlined,
  SmallDashOutlined,
  DeploymentUnitOutlined,
  PaperClipOutlined,
  VideoCameraOutlined,
  ToolOutlined,
} from '@ant-design/icons'

const { Header, Content, Footer } = Layout
const { SubMenu } = Menu

const AdminLayout = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(null)
  const history = useHistory()
  const { isLogin } = useLogin()

  const disconnect = () => {
    logout()
    history.push('/')
  }

  const routes = [
    { path: '/admin/*/edit/:id', breadcrumb: 'Edit' },
    { path: '/admin/', breadcrumb: 'Administration' },
  ]

  const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ['/admin/*/edit', '/admin', '/'] })

  const me = async () => {
    const res = await refreshToken()
    if (res.statusCode === 200) {
      localStorage.setItem('token', res.data.token)
      store.dispatch(MeSlice.actions.setMe(res.data.me))
      setCurrentUser(res.data.me)
    } else {
      localStorage.removeItem('token')
    }
  }
  React.useEffect(() => {
    me()
    document.title = 'Carver Scholars Program'
  }, [1])

  if (!isLogin) {
    return <Redirect to="/" />
  }

  const getReportName = () => {
    if (currentUser?.institution?.id > 3) {
      return 'Private College'
    } else if (currentUser?.institution?.id && currentUser?.institution?.id <= 3) {
      return 'University'
    } else {
      return ''
    }
  }

  return (
    <Layout className={'layout'}>
      <Header className={'no-print'}>
        <div className={'logo'} />
        <Menu theme={'dark'} mode="horizontal" defaultSelectedKeys={['2']}>
          {currentUser && currentUser.role === 'admin' && (
            <SubMenu icon={<MenuOutlined />} key="admin" title="Admin">
              <Menu.Item key="applications">
                <TableOutlined /> Applications
                <Link to="/admin/applications" />
              </Menu.Item>
              <Menu.Item key="users">
                <UserOutlined /> Users
                <Link to="/admin/users" />
              </Menu.Item>
              <Menu.Item key="institution">
                <HomeOutlined /> Institutions
                <Link to="/admin/institutions" />
              </Menu.Item>
              {currentUser.email.includes('imediasolutions') && (
                <Menu.Item key="tools">
                  <ToolOutlined /> Tools
                  <Link to="/admin/tools" />
                </Menu.Item>
              )}
            </SubMenu>
          )}
          <Menu.Item key="p">
            <ContainerOutlined /> Carver Scholars Applications
            <Link to="/admin/applications" />
          </Menu.Item>
          <SubMenu icon={<DatabaseOutlined />} key="private" title={`${getReportName()} Reports `}>
            {currentUser && currentUser.role === 'admin' && (
              <>
                <Menu.Item key="p1">
                  <BuildOutlined /> Summary
                  <Link to="/admin/reports/summary" />
                </Menu.Item>
                <Menu.Item key="p2">
                  <SmallDashOutlined />
                  Trustees
                  <Link to="/admin/reports/trustees" />
                </Menu.Item>
              </>
            )}
            <Menu.Item key="p3">
              <DeploymentUnitOutlined /> Award Recipients
              <Link to="/admin/reports/recipients" />
            </Menu.Item>
            <Menu.Item key="p4">
              <DollarCircleOutlined /> Admin Expense
              <Link to="/admin/reports/admin" />
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="p6" disabled={true}>
            Welcome {currentUser ? currentUser.email : ''}
          </Menu.Item>
          <Menu.Item key="p7">
            <a style={{ marginTop: 25 }} onClick={disconnect}>
              <LogoutOutlined /> Logout
            </a>
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <Row className={'no-print'}>
          <Col span={16}>
            <Breadcrumb style={{ margin: '8px 0' }}>
              {breadcrumbs.map(({ match, breadcrumb }, index) => (
                <Breadcrumb.Item key={index}>
                  <Link to={match.url}>{breadcrumb}</Link>
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </Col>
          <Col span={8} style={{ margin: '8px 0' }}>
            <p>
              Instructions:
              <Tag icon={<VideoCameraOutlined />} color="blue">
                <a target="blank" href="https://vimeo.com/manage/videos/619242492">
                  Application
                </a>
              </Tag>
              <Tag icon={<PaperClipOutlined />} color="green">
                <a target="blank" href="https://www.carvertrust.org/instructions.pdf">
                  Review
                </a>
              </Tag>
            </p>
          </Col>
        </Row>
        <div className="site-layout-content">{children}</div>
      </Content>
      <Footer style={{ textAlign: 'center' }}></Footer>
    </Layout>
  )
}

export default AdminLayout
