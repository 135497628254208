import React, { useState,useEffect } from 'react';
import 'antd/dist/antd.css';
import {Input,Space,Form} from 'antd'

const { TextArea } = Input;

export const Essay = (props: any) => {

  const [essay, setEssay] = React.useState(props.value);

  const [essayCount, setEssayCount] = React.useState(essay);

  const onEssayChange = (e: any) => {
    setEssay(e.currentTarget.value);
  };

  useEffect(() => {
    setEssayCount(essay.split(' ').length);
  },[essay]);

  return (
    <>
      <Form.Item 
        name='essay'
        wrapperCol={{
          span: 24,
        }}
        rules={[{ required: true, message: 'Essay Required' },
          () => ({
            validator: async (_, value) => {
              if (essayCount > props.max) {
                return Promise.reject(new Error(`The Essay can not have over ${props.max} words!`));
              }
            }
          })]}

      >
        <TextArea style={{height:250}} onChange={onEssayChange}/>
      </Form.Item>
      <p style={{
        textAlign:'center', color: essayCount > props.max ? 'red' : 'black'
      }}
      >
        {essayCount} / <Space /> {props.max}
      </p>
    </>
  );
};
