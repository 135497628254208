import React from 'react'

import { Application } from '../../components/application/application'
import { isApplicationProcessOpen } from '../../contants/options'
import { Redirect } from 'react-router'
import {
  useParams
} from "react-router-dom";
import { defaultRecord } from '../../contants/test.record'

const FirstYearScreen = () => {

  type applicationParams = {
    extension: string;
  };

  const { extension } = useParams<applicationParams>();
  if (!isApplicationProcessOpen(extension)) return <Redirect to="/" />
  return <Application record={
    process.env.NODE_ENV === 'development' ? defaultRecord : { addresses: [''], references: ['', '']
    }
  } />
}

export default FirstYearScreen
