import React, { useState } from 'react'
import 'antd/dist/antd.css'
import { Layout, Result } from 'antd'
import { InstitutionInfo } from '../institution/info'
import { Institution } from '../../models/institution.model'
import moment from 'moment'

const { Content } = Layout

export const Submission = (props: any) => {
  return (
    <Result
      style={{
        width: 800,
        margin: 'auto',
        padding: '25px',
      }}
      status="success"
      title="Your first year application was submitted!"
      subTitle={`Application was submitted on ${moment(props.application.submitted).format(
        'MMMM Do YYYY [at] h:mm a',
      )}. Please record the confirmation code: ${props.application.uuid} as proof of submission:`}
    >
      <p>
        Shortly you should be receiving an e-mail confirming submission of your application. If you prefer, you can also
        print this page for your records.
      </p>
      <p>Thank you for applying for a Carver Scholarship.</p>
      <p>
        <a href="http://www.carvertrust.org">Click here to return to the Carver Trust web site.</a>
      </p>
    </Result>
  )
}
