import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Space, Select, InputNumber, Row, Col } from 'antd'
import 'antd/dist/antd.css'
import { MinusSquareOutlined, ContactsOutlined, PlusOutlined } from '@ant-design/icons'
import { Phone } from './phone'
import { isValidPhoneNumber } from 'react-phone-number-input'

const { TextArea } = Input

export const References = props => {
  const defaultLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  }
  const childrenLayout = props.new ? {} : defaultLayout

  return (
    <Form.List
      name="references"
      rules={[
        {
          validator: async (_, names) => {
            if (!names || names.length != 2) {
              return Promise.reject(new Error('2 Letters of support are required'))
            }
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }, index) => (
            <>
              <h4 style={{ margin: '5px 0 10px 5px', fontWeight: 800 }}>Letter of Support #{index + 1}</h4>
              <Form.Item
                {...restField}
                {...childrenLayout}
                label="Name"
                name={[name, 'name']}
                fieldKey={[fieldKey, 'name']}
                rules={[{ required: true, message: 'Missing name' }]}
              >
                <Input size="large" placeholder="Contact Name" />
              </Form.Item>

              <Form.Item
                {...restField}
                {...childrenLayout}
                name={[name, 'phone']}
                label="Phone"
                fieldKey={[fieldKey, 'phone']}
                extra="If the number provided is a cellphone, a text notification wil be sent."
                rules={[
                  { required: true, message: 'Missing phone' },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      console.log(value, value.replace(/\D/g, '').toString().length, isValidPhoneNumber(value))
                      return !isValidPhoneNumber(value) || value.replace(/\D/g, '').toString().length !== 11
                        ? Promise.reject(new Error('Invalid Phone Number'))
                        : Promise.resolve('')
                    },
                  }),
                ]}
              >
                <Phone placeholder="Contact Telephone #" />
              </Form.Item>
              <Form.Item
                {...restField}
                {...childrenLayout}
                label="Email"
                name={[name, 'email']}
                fieldKey={[fieldKey, 'email']}
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Missing E-mail or is not valid.',
                  },
                ]}
              >
                <Input size={'large'} placeholder={'E-mail'} />
              </Form.Item>
              {props.new && (
                <Form.Item
                  {...restField}
                  {...childrenLayout}
                  label="Confirm Email"
                  name={[name, 'email-confirm']}
                  dependencies={['email']}
                  fieldKey={[fieldKey, 'email-confirm']}
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: 'Please confirm your email!',
                    },
                    ({ getFieldValue }) => ({
                      validator: async (_, value) => {
                        if (value && getFieldValue('email') !== value) {
                          new Error('The two emails that you entered do not match!')
                        }
                      },
                    }),
                  ]}
                >
                  <Input size={'large'} placeholder={'Confirm E-mail'} />
                </Form.Item>
              )}
              {!props.new && (
                <>
                  <Form.Item
                    {...restField}
                    {...childrenLayout}
                    fieldKey={[fieldKey, 'relation']}
                    name={[name, 'relation']}
                    label="Relationship"
                  >
                    <Input size={'large'} placeholder={'Relationship with Applicant'} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    {...childrenLayout}
                    fieldKey={[fieldKey, 'years']}
                    label="Years of Known"
                    name={[name, 'years']}
                  >
                    <InputNumber max={50} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    {...childrenLayout}
                    fieldKey={[fieldKey, 'title']}
                    label="Occupation Title"
                    name={[name, 'title']}
                  >
                    <Input size={'large'} placeholder={'Occupation Title'} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    {...childrenLayout}
                    fieldKey={[fieldKey, 'letter']}
                    label="Letter of Support"
                    name={[name, 'letter']}
                  >
                    <TextArea rows={10} />
                  </Form.Item>
                </>
              )}
            </>
          ))}
          {fields.length < 2 && (
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Letter of Support Person <ContactsOutlined />
              </Button>
            </Form.Item>
          )}
          <Form.ErrorList errors={errors} />
        </>
      )}
    </Form.List>
  )
}
