import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useLogin } from '../../utils/auth.utils'
import { isApplicationProcessOpen } from '../../contants/options'
import { Layout, Space, Button, Row } from 'antd'
const { Header, Footer, Sider, Content } = Layout

const HomeScreen = () => {
  const { isLogin } = useLogin()

  const open = isApplicationProcessOpen()
  return isLogin ? (
    <Redirect to="/admin" />
  ) : (
    <>
      <h1>ON-LINE APPLICATION PROCESS OVERVIEW FIRST-YEAR APPLICANTS ONLY</h1>
      <p>
        In order to be considered for a Carver Scholarship, first-year applicants must complete an on-line application
        form to be submitted electronically to the college or university you are planning to attend by clicking on the
        application link on this web site. Applications are accepted from October 1<sup>st</sup> through April 1
        <sup>st</sup>. The application form requires:
      </p>
      <p>
        <i className="fa fa-check fa-3" aria-hidden="true"></i>A typed essay presenting evidence of the unusual social
        and/or other barriers that may deter you from attending college full time. The document may be cut and pasted or
        processed directly into the on-line application form.{' '}
        <u>Essays should be a minimum of 250 words, but must not exceed 500.</u>
      </p>
      <p>
        <i className="fa fa-check fa-3" aria-hidden="true"></i>
        The names and e-mail addresses of two professional individuals (other than family members) to serve as
        references, whom you have asked to submit letters of support on your behalf, verifying that the information
        included in your essay is accurate. The purpose of the documentation is to confirm the difficulties you have
        overcome and to give insight into your character. At the time your application is submitted, the on-line system
        automatically forwards an e-mail to the address you provide for your references (teacher, physician, therapist,
        etc.). References should submit comments (limited to 250 words) via the link provided in their e-mail
        notification. These must be received by the April 5<sup>th</sup> submission deadline in order for your
        application to be considered complete.{' '}
        <i>
          Administrative officials at the participating institutions will confirm authenticity of electronically
          submitted reference materials in advance of the selection process.
        </i>
      </p>
      <p>
        <i className="fa fa-check fa-3" aria-hidden="true"></i>
        In addition to the on-line application materials, applicants will also be responsible for filing a{' '}
        <a href="http://www.fafsa.ed.gov/" target="blank">
          Free Application for Federal Student Aid (FAFSA)
        </a>{' '}
        by March 1 <sup>st</sup> of the award year for submission to the financial aid office of the college that you
        are planning to attend.
      </p>

      <p>
        Please make sure to have an outline of your essay and other supporting documentation available before you begin
        the on-line application process.
      </p>
      <p>
        <strong>
          ONCE YOU HAVE BEGUN TO INPUT DATA YOU CANNOT SAVE THE FORM AND RETURN TO IT LATER FOR ADDITIONAL INPUT.
        </strong>
      </p>
      <h3>APPLICATION DEADLINE</h3>
      <p style={{ textDecoration: 'underline' }}>
        Completed applications must be submitted on-line and received by
        <strong>
          {' '}
          April 1<sup>st</sup> at 10 PM CST
        </strong>
        . Applicants are encouraged to apply early.
      </p>
      <h3>NOTIFICATION OF AWARDS</h3>
      <p>
        All applicants should be notified of the outcome of the selection process by the college to which application
        was made by{' '}
        <strong>
          June 1<sup>st</sup> of each award year.
        </strong>
      </p>
      {open && (
        <Row justify="center" style={{ textAlign: 'center', marginTop: 25 }} align="top">
          <Link to="/first_year">
            <Button type="primary" shape="round" size="large">
              Apply First Year
            </Button>
          </Link>
        </Row>
      )}
    </>
  )
}

export default HomeScreen
