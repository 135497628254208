import React, { useState } from 'react';
import 'antd/dist/antd.css';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';


export const Phone = (props: any) => (
  <PhoneInput className='ant-input ant-input-lg' defaultCountry={"US"} country='US' {...props} />
);

