import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Radio, Space, Select } from 'antd';
import 'antd/dist/antd.css';
import {
  HomeOutlined,
  PlusOutlined,
  MinusSquareOutlined
} from '@ant-design/icons';
import {statesOptions, typeOptions} from "../../contants/options";

const { TextArea } = Input;
const { Option } = Select;
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 }
  }
};

export const Addresses = ({ onChange, fields }: any) => {

  const [typeValue, setTypeValue] = React.useState(1);
  const radioType = (e: any) => {
    setTypeValue(e.target.value);
  };

  return (
    <Form.List name='addresses'
      rules={[
        {
          validator: async (_, names) => {
            if (!names || names.length < 1) {
              return Promise.reject(new Error('At least 1 address is required'));
            }
          },
        },
      ]}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }, index) => (
            <>
              <h4 style={{ margin: '5px 0 10px 5px', fontWeight: 500 }}>Address #{index + 1}</h4>
              <Form.Item
                label='Type'
                name={[name, 'type']}
                fieldKey={[fieldKey, 'type']}
                rules={[{ required: true, message: 'Missing type' }]}

              >
                <Radio.Group
                  options={typeOptions}
                  optionType='button'
                  buttonStyle="solid"
                  onChange={radioType}
                  value={typeValue}
                />
              </Form.Item>
              <Form.Item
                name={[name, 'address']}
                fieldKey={[fieldKey, 'address']}
                rules={[{ required: true, message: 'Missing address' }]}
              >
                <Input size='large' placeholder='Address' />
              </Form.Item>
              <Form.Item>
                <Row gutter={2}>
                  <Col span={11}>
                    <Form.Item
                      {...restField}
                      name={[name, 'city']}
                      fieldKey={[fieldKey, 'city']}
                      rules={[{ required: true, message: 'Missing city' }]}
                    >
                      <Input size='large' placeholder='City' />
                    </Form.Item>
                  </Col>
                  <Col span={6} offset={1}>
                    <Form.Item
                      {...restField}
                      name={[name, 'state']}
                      fieldKey={[fieldKey, 'state']}
                      rules={[{ required: true, message: 'Missing state' }]}
                    >
                      <Select
                        size='large'
                        placeholder='Select a State'
                        options={statesOptions}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3} offset={1}>
                    <Form.Item
                      {...restField}
                      name={[name, 'zip']}
                      fieldKey={[fieldKey, 'zip']}
                      rules={[{ required: true, message: 'Missing zip' }]}
                    >
                      <Input size='large' placeholder='Zip' />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <MinusSquareOutlined
                      style={{float:'right', margin: '10px 0' }}
                      onClick={() => remove(name)} />
                  </Col>
                </Row>
              </Form.Item>
            </>
          ))}
          {fields.length < 2 &&
          <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
            Add Address <HomeOutlined />  
          </Button>
          }
          <Form.ErrorList errors={errors} />
        </>
      )}
    </Form.List>

  );
};
