import { APIResponse } from "../models/api.model";
import { Institution } from "../models/institution.model";
import {get, put} from "./utils.service";

export const findAll = async (): Promise<APIResponse<Institution[]>> => {
  const res = await get(`/institutions/findAll`);
  return await res.json();
};
export const findById = async (id): Promise<APIResponse<Institution>> => {
  const res = await get(`/institutions/findById/${id}`);
  return await res.json();
};
export const update = async (institution): Promise<APIResponse<Institution>> => {
  const res = await put(`/institutions/update`,institution);
  return await res.json();
};