import React, { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router'

import { useLogin } from '../../utils/auth.utils'
import { Form, Select, Button, Checkbox, Table, Popconfirm, message, Row, Col } from 'antd'
import { yearOptions } from '../../contants/options'
import { generateRenewals, sendReminders } from '../../services/applications.service'
import { Link } from 'react-router-dom'

const ToolsScreen = () => {
  const { me, isLogin } = useLogin()
  const [resultSet, setResultSet] = React.useState(null)
  const month = new Date().getMonth()

  const [year, setYear] = React.useState((new Date().getFullYear() + (month >= 6 ? 1 : 0)) as number)
  const [generate, setGenerate] = React.useState(false)
  const [send, setSend] = React.useState(false)

  const handleChange = async () => {
    const results = await generateRenewals(year, generate, send)
    setResultSet(results.data)
  }

  const handleSendReminders = async () => {
    const results = await sendReminders(year)
    message.info(`${results.data} reminders sent.`)
  }
  const handleYear = e => {
    setYear(e)
  }
  const handleGenerate = e => {
    setGenerate(!generate)
  }
  const handleSend = e => {
    setSend(!send)
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Institution',
      dataIndex: 'institution',
      key: 'institution',
    },
  ]

  if (!isLogin) return <Redirect to="/" />
  return (
    <>
      <Row style={{marginBottom: 15}}>
        <Col style={{textAlign:'right'}} span={24}>
          <Popconfirm
            title="This will send to all references that have not been sent a reminder in 3 days?"
            onConfirm={() => handleSendReminders()}
            okText="Yes" cancelText="No">
            <Button type="default"  style={{width:200}} >Send Reference Reminders</Button>
          </Popconfirm>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form method="GET" name="reports" initialValues={{ generate: false }} onFinish={handleChange}>
            <Form.Item name="year">
              <Select
                defaultValue={year}
                options={yearOptions().map(x => ({ value: x.value, label: `${x.label} - ${x.label + 1}` }))}
                onChange={handleYear}
              />
            </Form.Item>
            <Form.Item name="generate">
              <Checkbox checked={generate} onChange={handleGenerate}>Generate Renewals</Checkbox>{' '}
              <Checkbox checked={send} onChange={handleSend}>Notify Renewal Recipients</Checkbox>
            </Form.Item>
            <Button type="primary" style={{ float: 'right' }} htmlType="submit">
              Generate Renewals
            </Button>
          </Form>
          {resultSet && <Table dataSource={resultSet} columns={columns} pagination={false} />}
        </Col>
      </Row>

    </>
  )
}

export default ToolsScreen
