import React, { useState, useEffect, useMemo } from 'react'
import { Redirect } from 'react-router'
import 'react-phone-number-input/style.css'
import { create } from '../../services/applications.service'
import {
  Form,
  Radio,
  InputNumber,
  Layout,
  message,
  Input,
  Button,
  Tooltip,
  Space,
  Typography,
  Select,
  Row,
  Col,
  Checkbox,
  AutoComplete,
  Cascader,
  Steps,
  Divider,
} from 'antd'

import { References } from './references'
import { Submission } from './submission'
import { Main } from './main'
import { Essay } from './essay'
import 'antd/dist/antd.css'
//sample record for insert debugging etc
import { defaultRecord } from '../../contants/test.record'

const { Step } = Steps

type ApplicationProps = { record: any }

export const Application = ({ record }: ApplicationProps) => {
  const [form] = Form.useForm()
  const [submission, setSubmission] = React.useState(null)
  const [submitted, setSubmitted] = React.useState(false)

  const onFinish = async (values: any) => {
    setSubmitted(true)
    const res = await create(form.getFieldsValue(true))
    setSubmission(res.data)
  }

  const [currentStep, setCurrentStep] = React.useState(0)

  const prev = async () => {
    form
      .validateFields()
      .then(values => {
        setCurrentStep(currentStep - 1)
      })
      .catch(errorInfo => {
        console.log(errorInfo)
      })
  }

  const next = () => {
    form
      .validateFields()
      .then(values => {
        setCurrentStep(currentStep + 1)
      })
      .catch(errorInfo => {
        console.log(errorInfo)
      })
  }

  const Step1 = () => {
    return <Main form={form} new={true} />
  }

  const Step2 = () => {
    return (
      <Row justify="center">
        <Col span={18}>
          A typed essay presenting evidence of the unusual social and/or other barriers that may deter you from
          attending college full time. The document may be cut and pasted or processed directly into the on-line
          application form. Essays should be a minimum of 250 words, but must not exceed 500.
          <Essay rows={10} max={500} value={''} />
        </Col>
      </Row>
    )
  }

  const Step3 = () => {
    return (
      <Row justify="center">
        <Col span={18}>
          <p>
            The purpose of the documentation is to confirm the difficulties you have overcome and to give insight into
            your character. At the time your application is submitted, the on-line system automatically forwards an
            e-mail to the address you provide for your references (teacher, physician, therapist, etc.).
          </p>

          <p>
            Please enter two people that can provide you a letter of support. They will be emailed information on how to
            complete the letter of support. They will also be send a text message notification, so it is important that
            we get their phone number.
          </p>

          <p>
            You will get notifications each time we notify this person, so make sure you have selected the correct email
            address.
          </p>
          <References new={true} />
        </Col>
      </Row>
    )
  }

  const Step4 = () => {
    return (
      <Row justify="center">
        <Col span={18}>
          We request this information solely to evaluate your application for the Roy J. Carver Statewide Scholarship
          Program. Responses to all items are required in order to permit your application to be considered. Any
          falsified information will result in disqualification of this application. Agreeing to the terms set forth in
          this Certificate of Authorization permits the scholarship selection committee to contact your references, if
          necessary, to verify authenticity of their letters of support. It will also authorize the institution to which
          you are applying to publicize your name in connection with this scholarship if selected as a recipient, and
          allows information from your financial aid application to be reviewed by the selection committee and the Roy
          J. Carver Charitable Trust. All information will be treated with the strictest of confidence.
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: async (_, value) => {
                  if (!value) {
                    new Error('You must accept the agreement')
                  }
                },
              },
            ]}
          >
            <Checkbox>
              I have read the <a href="">agreement</a>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    )
  }

  const steps = [
    {
      title: 'General Information',
      content: <Step1 />,
    },
    {
      title: 'Essay',
      content: <Step2 />,
    },
    {
      title: 'Letter of Support',
      content: <Step3 />,
    },
    {
      title: 'Certificate of Authorization',
      content: <Step4 />,
    },
  ]
  return (
    <>
      {submission && <Submission application={submission} />}
      {!submission && (
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={record}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
        >
          <Row justify="center">
            <Col span={17}>
              <Steps current={currentStep}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col>
          </Row>
          <Divider orientation="left" />

          {steps[currentStep].content}
          <Divider orientation="center" />
          <Row justify="center">
            <Col span={5}>
              <div className="steps-action">
                {currentStep > 0 && (
                  <Button style={{ float: 'left' }} onClick={() => prev()}>
                    Previous
                  </Button>
                )}
                {currentStep < steps.length - 1 && (
                  <Button style={{ float: 'right' }} type="primary" onClick={() => next()}>
                    Next
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button type="primary" disabled={submitted} style={{ float: 'right' }} htmlType="submit">
                    Submit Application
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}
