import React from 'react'
import { Result } from 'antd'
import moment from 'moment'

const Report5Screen = () => {
  return (
    <Result
      style={{
        width: 800,
        margin: 'auto',
        padding: '25px',
      }}
      status="info"
      title="Adminstrative Expense Report"
      subTitle=""
    >
      <p style={{ textAlign: 'center' }}>
        Click <a href="https://www.carvertrust.org/application/cadmin/admin_report5.doc">here</a> to download the
        Administrative Expense Report (for prior Academic Year) to fill out and send to The Carver Trust by September
        30th (prior Academic Year).
      </p>
    </Result>
  )
}
export default Report5Screen
