import React, { useEffect } from 'react'
import {

  useParams
} from "react-router-dom";
import {  findByIdOpen } from '../../services/applications.service'

import { Form } from 'antd'
import { Renewal } from '../../components/application/renewal'
import { Application } from '../../components/application/application'
import { Application as ApplicationInterface }  from '../../models/application.model'

const AdhocScreen = () => {

  const [loaded, setLoaded] = React.useState(false)
  const [application, setApplication] = React.useState<ApplicationInterface>(null)
  const [user, setUser] = React.useState(null)
  const [form] = Form.useForm()
  type applicationParams = {
    uuid: string
  };

  const getApplication = async uuid => {
    return findByIdOpen(uuid).then(_ => {
      if (_.data) {
        const app = _.data
        app.institution = app.institution?.id
        setApplication(app)
        return Promise.resolve(true)
      } else {
        return Promise.reject(false)
      }
    })
  }
  const { uuid} = useParams<applicationParams>();

  useEffect(() => {
    if (uuid !== undefined) {
      getApplication(uuid).then(_ => setLoaded(true))
    }
  }, [uuid])

  return (loaded && application && (
    <>
      {application?.renewal && (<Renewal application={application} />)}
      {!application?.renewal && (<Application record={application} />)}
    </>
  ))
}

export default AdhocScreen
