import { get } from './utils.service'
import { APIResponse } from '../models/api.model'

export const report23 = async (year: number, institutionId: number, renewal: number): Promise<APIResponse<any[]>> => {
  const res = await get(`/reports/report23?year=${year}&renewal=${renewal}&institutionId=${institutionId}`)
  return await res.json()
}
export const report1 = async (year: number, isPrivate: boolean): Promise<APIResponse<any[]>> => {
  const res = await get(`/reports/report1?year=${year}&private=${isPrivate}`)
  console.log(res)
  return await res.json()
}
