import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Form, Input, Select, List, Typography, Radio, message, Col, Row, Card, Space } from 'antd'
import { PhoneOutlined, MailOutlined, FileSearchOutlined, PrinterOutlined } from '@ant-design/icons'
import moment from 'moment'
import { statusOptions } from '../../contants/options'
import '../../index.css'
import PhoneNumber from 'awesome-phonenumber'

const ApplicationPrint = props => {
  const params = useParams()
  const id = params['id']
  const [application, setApplication] = React.useState(null)
  const [user, setUser] = React.useState(null)
  const [form] = Form.useForm()

  const onPrint = () => {
    window.print()
  }

  useEffect(() => {
    setApplication(props.record)
  }, [props.record])

  return (
    <div id="content" ref={props.ref}>
      {application && (
        <Form
          form={form}
          name="User"
          onFinish={onPrint}
          initialValues={application}
          scrollToFirstError
          labelCol={{
            span: 8,
            offset: 0,
            style: { fontWeight: 600 },
          }}
          wrapperCol={{
            span: 12,
            offset: 0,
          }}
          layout="horizontal"
        >
          <h2
            className={'print-only'}
            style={{ textAlign: 'center', borderBottom: '1px solid black', paddingBottom: 5 }}
          >
            {props.title}
          </h2>

          <Row gutter={16}>
            <Col span={12}>
              <Card
                headStyle={{ fontWeight: 'bolder' }}
                title={`${application.firstname} ${application.middlename} ${application.lastname}`}
              >
                <PhoneOutlined /> {application.phone ? PhoneNumber(application.phone, 'US').getNumber('national') : ''}
                <br /> <MailOutlined /> {application.email}
              </Card>
            </Col>
            <Col span={12}>
              <Card headStyle={{ fontWeight: 'bolder' }} title={'Submission Information'}>
                <Form.Item label={'Type'}>{application.renewal ? 'Renewal' : 'First Year'}</Form.Item>
                <Form.Item label={'Date Submitted'}>{moment(application.submitted).format('LL')}</Form.Item>
                <Form.Item label={'Application Status'}>
                  {statusOptions.find(x => x.value == application.status).label}
                </Form.Item>
                <Form.Item label={'Last Modified'}>{moment(application.updated).format('LL')}</Form.Item>
              </Card>
            </Col>
          </Row>
          <Row gutter={16}>
            {application.addresses &&
              application.addresses.map(a => (
                <Col span={12}>
                  <Card headStyle={{ fontWeight: 'bolder' }} title={`${a.type} Address`}>
                    {a.address} <br />
                    {a.city} {a.state}, {a.zip}
                    <br />
                  </Card>
                </Col>
              ))}
          </Row>
          <Row gutter={16}>
            {application.employments &&
              application.employments.map((a, i) => (
                <Col span={12}>
                  <Card headStyle={{ fontWeight: 'bolder' }} title={`Employer #${i + 1} ${a.employer}`}>
                    {a.title}
                    <br />
                    {a.city} {a.state}, {a.zip}
                    <br />
                    Contact: {a.name} <Space />
                    <PhoneOutlined />
                    {a.phone ? PhoneNumber(a.phone, 'US').getNumber('national') : ''} <br />
                    Employed from: {moment(a.date_from).format('LL')} To {moment(a.date_to).format('LL')}
                  </Card>
                </Col>
              ))}
          </Row>
          <Row gutter={16}>
            <Col span={12} style={{ paddingTop: 20 }}>
              <Card headStyle={{ fontWeight: 'bolder' }} title="Personal Information">
                <Form.Item label={'Age'}>{application.age}</Form.Item>
                <Form.Item label={'Dependents'}>{application.dependents}</Form.Item>
                <Form.Item label={'Married'}>{application.married ? 'Yes' : 'No'}</Form.Item>
              </Card>
            </Col>
            <Col span={12}>
              <Card headStyle={{ fontWeight: 'bolder' }} title="Academic Information">
                <Form.Item label={'GPA'}>{application.gpa}</Form.Item>
                <Form.Item label={'Major'}>{application.major}</Form.Item>
                <Form.Item label={'Transfer'}>
                  {application.transfer ? 'Yes' : 'No'} {application.attending}
                </Form.Item>
              </Card>
            </Col>
          </Row>
          <Card headStyle={{ fontWeight: 'bolder', paddingBottom: 15 }} title={`Letters of Support`}>
            {application.references.map((r, i) => (
              <Card headStyle={{ fontWeight: 'bolder' }} title={`Letter #${i + 1}`}>
                <Form.Item label={'Name'}>{r.name}</Form.Item>
                <Form.Item label={'Email'}>{r.email}</Form.Item>
                <Form.Item label={'Phone'}>{r.phone ? PhoneNumber(r.phone, 'US').getNumber('national') : ''}</Form.Item>
                <Form.Item label={'Title'}>{r.title}</Form.Item>
                <Form.Item label={'Years Acquainted'}>{r.years}</Form.Item>
                <p className={'block'}>{r.letter}</p>
              </Card>
            ))}
          </Card>
          <Card headStyle={{ fontWeight: 'bolder', paddingBottom: 25, marginTop: '10mm' }} title={`Essay`}>
            <p className={'block'}>{application.essay}</p>
          </Card>
        </Form>
      )}
    </div>
  )
}

export default ApplicationPrint
