import React, { useEffect } from 'react'
import { report1, report23 } from '../../services/reports.service'
import { useLogin } from '../../utils/auth.utils'
import 'antd/dist/antd.css'
import { Button, Col, Form, Row, Select, Switch } from 'antd'
import { getCurrentYearReports, institutionOptions, yearOptions } from '../../contants/options'
import { useLocation } from 'react-router-dom'

const Report4Screen = () => {
  const { me, isLogin } = useLogin()
  const [isPrivate, setPrivate] = React.useState(!!(me?.institution?.id && me.institution?.id > 3))
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  })
  const [resultSet, setResultSet] = React.useState(null)
  const [summarySet, setSummarySet] = React.useState(null)
  const [year, setYear] = React.useState(getCurrentYearReports())
  const [form] = Form.useForm()

  const handlePrivate = e => {
    setPrivate(e)
  }

  const handleYear = e => {
    setYear(e)
  }

  const getSummaryTotals = () => {
    const summary = { general: {}, majors: {} }
    console.log(resultSet)
    if (resultSet) {
      resultSet.general.map(object => {
        for (const property in object) {
          if (!summary.general[property]) {
            summary.general[property] = 0
          }
          summary.general[property] += new Number(object[property])
        }
      })
      resultSet.majors.forEach(m => {
        if (!summary.majors[m.major]) {
          summary.majors[m.major] = 0
        }
        summary.majors[m.major] += new Number(m.total)
      })
    }
    console.log('summary', summarySet)
    return summary
  }

  const handleChange = async () => {
    setResultSet(null)
    const results = await report1(year, isPrivate)
    if (results) {
      setResultSet(results.data)
      setSummarySet(getSummaryTotals())
    }
  }
  const totalRenewals = () => resultSet.awards.reduce((acc, val) => acc + parseFloat(val.renewals), 0)
  const totalFirstYear = () => resultSet.awards.reduce((acc, val) => acc + parseFloat(val.first_year), 0)
  const totalAmount = () => resultSet.awards.reduce((acc, val) => acc + parseFloat(val.amount_awarded), 0)

  useEffect(() => {
    handleChange()
  }, [year, isPrivate])

  return (
    <div className={'center'}>
      <h2>Recipient 1</h2>
      {me?.role === 'admin' && (
        <Form
          form={form}
          name="reports"
          onFinish={handleChange}
          labelCol={{
            span: 4,
            offset: 2,
          }}
          wrapperCol={{
            span: 12,
          }}
          layout="horizontal"
        >
          <Form.Item label="Year">
            <Row>
              <Col span={10}>
                <Form.Item name="year" initialValue={year} required={true}>
                  <Select
                    options={yearOptions().map(x => ({ value: x.value, label: `${x.label} - ${x.label + 1}` }))}
                    onChange={handleYear}
                  />
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item name="isPrivate">
                  <Switch checkedChildren="Private" onChange={handlePrivate} unCheckedChildren="Public" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      )}
      {resultSet && (
        <div>
          <h1>
            {!!isPrivate && <>Private Institutions of Higher Learning Summary Information</>}
            {!isPrivate && <>Regents' Universities</>} Summary Information
          </h1>
          <div className={'ant-table-content'}>
            Based on information by the {!!isPrivate && <> 22 Private Institution</>}
            {!isPrivate && <>3 Regents' Universities</>}, the following statistics apply to the{' '}
            <>
              {year} - {year + 1}
            </>{' '}
            Carver Scholar award recipients.
            <>
              {`${formatter.format(totalAmount())} in funding was paid by the Trust in April ${year}, for a total of 
              ${formatter.format(totalAmount() * 2)}. To date, this amount has been awarded to fund ${totalFirstYear()} 
              first-year scholarships and ${totalRenewals()} second-year renewals.`}
            </>
            <table cellPadding={5} className={'antd-table'}>
              <thead className={'antd-table-head'}>
                <tr style={{ border: '1px solid black' }}>
                  <th style={{ border: '1px solid black' }} align={'center'} rowSpan={2}>
                    FIRST YEAR APPLICANTS
                  </th>
                  <th style={{ border: '1px solid black' }} align={'center'} colSpan={5}>
                    APPLICANTS
                  </th>
                  <th style={{ border: '1px solid black' }} align={'center'} colSpan={5}>
                    AWARDS
                  </th>
                </tr>
                <tr style={{ border: '1px solid black' }}>
                  <th style={{ border: '1px solid black' }} align={'center'}>
                    1st YR APPS TOTAL
                  </th>
                  <th style={{ border: '1px solid black' }} align={'center'}>
                    4 YR APPLICATIONS
                  </th>
                  <th style={{ border: '1px solid black' }} align={'center'}>
                    COMMUNITY COLLEGE APPLICANTS TOTAL
                  </th>
                  <th style={{ width: 25, border: '1px solid black' }} align={'center'}>
                    TOTAL NO. AWARDS
                  </th>
                  <th style={{ border: '1px solid black' }} align={'center'}>
                    AVG. GPA
                  </th>
                  <th style={{ border: '1px solid black' }} align={'center'}>
                    AGE RANGE
                  </th>
                  <th style={{ border: '1px solid black' }} align={'center'}>
                    AVG AGE
                  </th>
                  <th style={{ border: '1px solid black' }} align={'center'}>
                    AVG FINANCIAL AID NEEDED
                  </th>
                  <th style={{ border: '1px solid black' }} align={'center'}>
                    MAJOR
                  </th>
                </tr>
              </thead>
              {summarySet && (
                <tbody>
                  <tr>
                    <td valign={'top'}>
                      <small>
                        {Math.round(
                          (resultSet.general.reduce((acc, val) => acc + parseFloat(val.dependents), 0) /
                            resultSet.general.reduce((acc, val) => acc + parseFloat(val.first_year), 0)) *
                            100,
                        )}{' '}
                        % OF RECIPIENTS HAVE DEPENDENTS
                        <br />
                        {Math.round(
                          (resultSet.general.reduce((acc, val) => acc + parseFloat(val.transfer), 0) /
                            resultSet.general.reduce((acc, val) => acc + parseFloat(val.first_year), 0)) *
                            100,
                        )}{' '}
                        % OF RECIPIENTS ARE COMMUNITY COLLEGE TRANSFER
                      </small>{' '}
                    </td>
                    <td valign={'top'} align={'center'}>
                      {resultSet.general.reduce((acc, val) => acc + parseFloat(val.first_year), 0)}
                    </td>
                    <td valign={'top'}>
                      {resultSet.general.reduce((acc, val) => acc + new Number(val.non_transfer), 0).toFixed()}
                    </td>
                    <td valign={'top'}>
                      {resultSet.general.reduce((acc, val) => acc + new Number(val.transfer), 0).toFixed()}
                    </td>
                    <td valign={'top'}>{resultSet.awards.reduce((acc, val) => acc + parseFloat(val.total), 0)}</td>
                    <td valign={'top'}>
                      {(
                        resultSet.awards.reduce((acc, val) => acc + parseFloat(val.avg_gpa), 0.0) /
                        resultSet.awards.length
                      ).toFixed(2)}
                    </td>
                    <td valign={'top'}>
                      {(
                        resultSet.awards.reduce((acc, val) => acc + parseFloat(val.min_age), 0.0) /
                        resultSet.awards.length
                      ).toFixed()}
                      -
                      {(
                        resultSet.awards.reduce((acc, val) => acc + parseFloat(val.max_age), 0.0) /
                        resultSet.awards.length
                      ).toFixed()}
                    </td>
                    <td valign={'top'}>
                      {(
                        resultSet.awards.reduce((acc, val) => acc + parseFloat(val.avg_age), 0) /
                        resultSet.awards.length
                      ).toFixed()}
                    </td>
                    <td valign={'top'}>
                      {formatter.format(
                        resultSet.awards.reduce((acc, val) => acc + parseFloat(val.amount_requested || 0), 0.0) /
                          resultSet.awards.length,
                      )}
                    </td>
                    <td valign={'top'}>
                      {summarySet.majors && (
                        <>
                          {Object.keys(summarySet.majors)
                            .sort()
                            .map((major, index) => (
                              <small>
                                {summarySet.majors[major]} {major}
                                <br />
                              </small>
                            ))}
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}
    </div>
  )
}
export default Report4Screen
