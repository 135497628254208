import { APIResponse } from '../models/api.model'
import { Application } from '../models/application.model'
import { get, post, put } from './utils.service'
import { Reference } from '../models/reference.model'

export const findAll = async (skip, take, filters, sort): Promise<any> => {
  const res = await get(
    `/applications/findAll/${skip}/${take}?sort=${encodeURIComponent(
      JSON.stringify(sort),
    )}&filters=${encodeURIComponent(JSON.stringify(filters))}`,
  )
  return await res.json()
}

export const create = async (application: Application): Promise<APIResponse<{ success: boolean }>> => {
  const res = await post(`/applications/apply`, application)
  return await res.json()
}

export const findById = async (id): Promise<APIResponse<Application>> => {
  const res = await get(`/applications/findById/${id}`)
  return await res.json()
}

export const findByIdOpen = async (id): Promise<APIResponse<Application>> => {
  const res = await get(`/applications/findOneOpen/${id}`)
  return await res.json()
}

export const update = async (application): Promise<APIResponse<Application>> => {
  const res = await put(`/applications/update`, application)
  return await res.json()
}

export const renewalSubmit = async (record): Promise<Reference> => {
  const res = await put(`/applications/renewalSubmit`, record)
  return await res.json()
}

export const renewalRequest = async (uuid): Promise<any> => {
  const res = await get(`/applications/renewalRequest/${uuid}`)
  return await res.json()
}
export const generateRenewals = async (year: number, generate = false, send = false): Promise<APIResponse<any[]>> => {
  const res = await get(`/applications/generateRenewals?year=${year}&generate=${generate}&send=${send}`)
  return await res.json()
}
export const generateRenewal = async (appId: number, generate = false, send = false): Promise<APIResponse<any[]>> => {
  const res = await get(`/applications/generateRenewal?appId=${appId}&generate=${generate}&send=${send}`)
  return await res.json()
}
export const sendReminders = async (year: number): Promise<APIResponse<any[]>> => {
  const res = await get(`/applications/sendReminders?year=${year}`)
  return await res.json()
}
