import { APIResponse } from "../models/api.model";
import { User } from "../models/user.model";
import {get, put} from "./utils.service";

export const getMe = async (): Promise<APIResponse<User>> => {
  const res = await get(`/users/me`);
  return await res.json();
};

export const findAll = async (): Promise<APIResponse<User[]>> => {
  const res = await get(`/users/findAll`);
  return await res.json();
};
export const findById = async (id): Promise<APIResponse<User>> => {
  const res = await get(`/users/findById/${id}`);
  return await res.json();
};
export const update = async (user): Promise<APIResponse<User>> => {
  const res = await put(`/users/update`,user);
  return await res.json();
};