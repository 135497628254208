import React, { useEffect } from 'react'
import { Form, Input, Result, Button, Typography, Space, Select, InputNumber, Divider, Row, Col } from 'antd'
import 'antd/dist/antd.css'
import { renewalSubmit } from '../../services/applications.service'
import moment from 'moment'
const { Text } = Typography
import { Main } from './main'

export const Renewal = props => {
  const [form] = Form.useForm()

  const [renewal, setRenewal] = React.useState(null)

  const onFinish = async (values: any) => {
    const res = await renewalSubmit(form.getFieldsValue(true))
    setRenewal(res)
  }

  return (
    <>
      {renewal && (
        <Result
          style={{
            width: 800,
            margin: 'auto',
            padding: '25px',
          }}
          status="success"
          title="Your renewal has been submitted!"
          subTitle={`Your renewal was submitted on on ${moment(renewal.submitted).format('MMMM Do YYYY, h:mm a')}. `}
        >
          <p>You and the institution will be receiving an e-mail confirming the renewal application.</p>
          <p>
            <a href="//www.carvertrust.org">Click here to return to the Carver Trust web site</a>
          </p>
        </Result>
      )}
      {!props.application && !renewal && (
        <Result
          status="error"
          title="Request Failed"
          subTitle={
            'Please check your email and try the link again. ' +
            'Otherwise your renewal may have been submitted already.'
          }
        >
          <div className="desc">
            <p>
              <Text strong style={{ fontSize: 16 }} />
            </p>
          </div>
        </Result>
      )}
      {props.application && !renewal && (
        <Form
          form={form}
          name="renewal"
          onFinish={onFinish}
          initialValues={props.application}
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
        >
          <Row justify="center">
            <Col span={18}>
              <h1>Roy J. Carver Statewide Scholarship Program 2nd Year Renewal Application</h1>
              <p>
                Please verify the following information. If you are satisfied with the information integrity, please
                click Submit Renewal
              </p>
              <Divider />
            </Col>
          </Row>
          <Divider orientation="left" />

          <Main application={props.application} renewal={true} new={false} />

          <Row justify="center">
            <Col span={6}>
              <Button type="primary" htmlType="submit">
                Submit Renewal
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}
