import React from 'react'
import AdminLayout from '../../components/layouts/admin.layout.component'
import { Link, Redirect } from 'react-router-dom'
function AdminScreen() {
  return (
    <>
      Welcome to the new Admin screen start by reviewing your <Link to="/admin/applications">applications</Link>
    </>
  )
}

export default AdminScreen
