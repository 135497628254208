import React from 'react'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import HomeScreen from './home/home.screen'
import FirstYearScreen from './home/firstyear.screen'
import SigninScreen from './home/signin.screen'
import ForgotScreen from './home/forgot.screen'
import SignupScreen from './signup/signup.screen'
import UserScreen from './admin/user.screen'
import LetterScreen from './home/letter.screen'
import InstitutionScreen from './admin/institution.screen'
import ApplicationScreen from './admin/application.screen'
import AdminScreen from './admin/admin.screen'
import ToolsScreen from './admin/tools.screen'
import Report1Screen from './admin/report1.screen'
import Report23Screen from './admin/report23.screen'
import Report4Screen from './admin/report4.screen'
import Report5Screen from './admin/report5.screen'
import NotFoundScreen from './404/not-found.screen'
import AdminLayout from '../components/layouts/admin.layout.component'
import DefaultLayout from '../components/layouts/default.layout.component'
import InstitutionEdit from './admin/institution.edit'
import UserEdit from './admin/user.edit'
import ApplicationEdit from './admin/application.edit'
import RenewalScreen from './home/renewal.screen'
import AdhocScreen from './home/adhoc.screen'
const PagesNavigation = () => {
  return (
    <Router>
      <Switch>
        <RouteWrapper exact path="/" component={HomeScreen} layout={DefaultLayout} />
        <RouteWrapper exact path="/letter/" component={LetterScreen} layout={DefaultLayout} />
        <RouteWrapper exact path="/first_year" component={FirstYearScreen} layout={DefaultLayout} />
        <RouteWrapper exact path="/first_year/extension/:extension" component={FirstYearScreen}
          layout={DefaultLayout} />
        <RouteWrapper exact path="/adhoc/uuid/:uuid" component={AdhocScreen}
          layout={DefaultLayout} />
        <RouteWrapper exact path="/renewal" component={RenewalScreen} layout={DefaultLayout} />
        <RouteWrapper exact path="/login" component={SigninScreen} layout={DefaultLayout} />
        <RouteWrapper exact path="/signup" component={SignupScreen} layout={DefaultLayout} />
        <RouteWrapper exact path="/forgot" component={ForgotScreen} layout={DefaultLayout} />
        <RouteWrapper exact path="/forgot/:token" component={ForgotScreen} layout={DefaultLayout} />
        <RouteWrapper exact path="/404" component={NotFoundScreen} layout={DefaultLayout} />
        <RouteWrapper exact path="/admin/" component={AdminScreen} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/tools" component={ToolsScreen} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/users" component={UserScreen} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/users/edit/:id" component={UserEdit} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/reports" component={Report23Screen} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/applications" component={ApplicationScreen} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/applications/edit/:id" component={ApplicationEdit} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/institutions" component={InstitutionScreen} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/institutions/edit/:id" component={InstitutionEdit} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/reports/summary" component={Report1Screen} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/reports/recipients" component={Report23Screen} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/reports/trustees" component={Report4Screen} layout={AdminLayout} />
        <RouteWrapper exact path="/admin/reports/admin" component={Report5Screen} layout={AdminLayout} />

        <Redirect to="/" />
      </Switch>
    </Router>
  )

  function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <Layout {...props}>
              <Component {...props} />
            </Layout>
          )
        }}
      />
    )
  }
}

export default PagesNavigation
