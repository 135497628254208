import moment from 'moment'

export const yesNoOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
]

export const typeOptions = [
  { label: 'Permanent', value: 'Permanent' },
  { label: 'Current', value: 'Current' },
]
export const classOptions = [
  { label: 'Junior', value: 'Junior' },
  { label: 'Senior', value: 'Senior' },
]

export const dependentsOptions = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3+', value: 3 },
]

export const yearOptions = () => {
  const years = []
  const year = new Date().getFullYear() + 1
  for (let x = year; x >= 2010; x--) {
    years.push({ label: x, value: x })
  }
  return years
}

export const dateEqualsToday = compare => {
  return new Date(compare).toLocaleDateString() === new Date().toLocaleDateString()
}

export const isApplicationProcessOpen = (slug = '') => {
  const dateFrom = moment('2024-10-01 00:00:01').unix()
  const dateTo = slug !== 'extension2024' ? moment('2025-04-01 22:00:00').unix() : moment('2024-04-04 23:59:59').unix()
  const now = moment().unix()
  return now >= dateFrom && now < dateTo
}

export const isReferenceProcessOpen = () => {
  const dateFrom = moment('2024-10-01 00:00:01').unix()
  const dateTo = moment('2025-04-03 23:23:59').unix()
  const now = moment().unix()
  return now >= dateFrom && now < dateTo
}

export const isRenewalProcessOpen = () => {
  const dateFrom = moment('2024-10-01 00:00:01').unix()
  const dateTo = moment('2025-05-01 23:23:59').unix()
  const now = moment().unix()
  return now >= dateFrom && now < dateTo
}
export const getCurrentYearReports = () => {
  const m: number = Number.parseInt(moment().format('M'))
  const y: number = Number.parseInt(moment().format('YYYY'))
  if (m < 10 && m >= 4) {
    return y
  } else if (m > 4) {
    return y + 1
  } else {
    return y - 1
  }
}
export const getYearFromSubmissionDate = (submission: Date) => {
  if (!submission) {
    return ''
  }
  const m: number = Number.parseInt(moment(submission).format('M'))
  const y: number = Number.parseInt(moment(submission).format('YYYY'))

  if (m < 10) {
    return y - 1
  } else {
    return y
  }
}

export const statusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'Cancelled', value: 'canceled' },
  { label: 'Waiting 1 References', value: 'reference1' },
  { label: 'Waiting 2 References', value: 'reference2' },
  { label: 'Complete', value: 'complete' },
  { label: 'Pending', value: 'pending' },
  { label: 'Dropped', value: 'dropped' },
]

export const reviewStatusOptions = [
  { label: 'Awarded', value: 'awarded' },
  { label: 'Denied', value: 'denied' },
  { label: 'Pending', value: 'pending' },
  { label: 'Alternate', value: 'alternate' },
  { label: 'Dropped', value: 'dropped' },
  { label: 'Not Reviewed', value: 'not_reviewed' },
]

export const statesOptions = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
].map(x => {
  return { label: x, value: x }
})

export const institutionOptions = [
  { label: 'Iowa State University', value: 1 },
  { label: 'University of Northern Iowa', value: 2 },
  { label: 'University of Iowa', value: 3 },
  { label: 'Briar Cliff', value: 28 },
  { label: 'Buena Vista University', value: 29 },
  { label: 'Central College', value: 30 },
  { label: 'Clarke University', value: 31 },
  { label: 'Coe College', value: 32 },
  { label: 'Cornell College', value: 33 },
  { label: 'Dordt University', value: 34 },
  { label: 'Drake University', value: 35 },
  { label: 'Graceland University', value: 36 },
  { label: 'Grand View University', value: 37 },
  { label: 'Grinnell College', value: 38 },
  // { label: 'Iowa Wesleyan University', value: 39 },
  { label: 'Loras College', value: 40 },
  { label: 'Luther College', value: 41 },
  { label: 'Morningside College', value: 42 },
  { label: 'Mount Mercy University', value: 43 },
  { label: 'Northwestern College', value: 44 },
  { label: 'Simpson College', value: 45 },
  { label: 'St. Ambrose University', value: 46 },
  { label: 'University of Dubuque', value: 47 },
  { label: 'Upper Iowa University', value: 48 },
  { label: 'Waldorf  College', value: 49 },
  { label: 'Wartburg College', value: 50 },
  { label: 'Test Institution', value: 51 },
]
