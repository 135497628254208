import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { User} from '../../models/user.model'
import { Button, Form, Input, Select, Radio, message } from 'antd'

import { findById, update } from '../../services/users.service'
import { findAll } from '../../services/institutions.service'

const { Password } = Input

const roles = [
  { label: 'Admin', value: 'admin' },
  { label: 'Institution', value: 'institution' },
]

const UserEdit = () => {
  const params = useParams()
  const id = params['id']
  const [institutions, setInstitutions] = React.useState(null)
  const [institution, setInstitution] = React.useState(null)
  const [role, setRole] = React.useState(null)
  const [user, setUser] = React.useState(null)
  const [form] = Form.useForm()

  const getInstitutions = async () => {
    const u = await findAll()
    setInstitutions(
      u.data.map(x => {
        return { label: x.name, value: x.id }
      }),
    )
  }

  const getUser = async id => {
    const record = await findById(id)
    setRole(record.data.role)
    setInstitution(record.data.institution)
    const {institution, ...rest} = record.data
    return setUser(rest)
  }
  const onRoleChange = (e: any) => {
    setRole(e.target.value)
  }

  useEffect(() => {
    getInstitutions()

    if (id !== undefined) {
      getUser(id)
    }
  }, [id])

  const onFinish = async (values: any) => {
    const res = await update(form.getFieldsValue(true))
    setUser(res.data)
    message.info('User updated')
  }

  return (
    <>
      {user && (
        <Form
          form={form}
          name="User"
          onFinish={onFinish}
          initialValues={user}
          scrollToFirstError
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
        >
          <Form.Item
            name="role"
            label="Role"
            rules={[
              {
                required: true,
                message: 'Please the user role.',
              },
            ]}
          >
            <Radio.Group
              onChange={onRoleChange}
              optionType={'button'}
              buttonStyle={'solid'}
              value={role}
              options={roles}
            />
          </Form.Item>
          {role === 'institution' && (
            <Form.Item
              name="institution"
              label="Institution"
              initialValue={institution.id}
              rules={[
                {
                  required: true,
                  message: 'Select the institution .',
                },
              ]}
            >
              <Select size="large" placeholder="Select an institution if role is institution" options={institutions} />
            </Form.Item>
          )}

          <Form.Item
            required
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter your E-mail!',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item name="password" label="Password">
            <Input size="large" type="password" placeholder={'leave blank to not change password'} />
          </Form.Item>

          <Button type="primary" style={{ float: 'right' }} htmlType="submit">
            Save
          </Button>
        </Form>
      )}
    </>
  )
}

export default UserEdit
