import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Form, Input, Select, Radio, message } from 'antd'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Phone } from '../../components/application/phone'
import { findById, update } from '../../services/institutions.service'
import { statesOptions, yesNoOptions } from '../../contants/options'

const { TextArea } = Input

const InstitutionEdit = () => {
  const params = useParams()
  const id = params['id']
  const [inst, setInstitution] = React.useState(null)

  const [privateValue, setPrivateValue] = React.useState(1)
  const radioPrivate = (e: any) => {
    setPrivateValue(e.target.value)
  }

  const [enableValue, setEnableValue] = React.useState(1)
  const radioEnable = (e: any) => {
    setEnableValue(e.target.value)
  }

  const [form] = Form.useForm()

  const getInstitution = async id => {
    const record = await findById(id)
    return setInstitution(record.data)
  }

  useEffect(() => {
    if (id !== undefined) {
      getInstitution(id)
    }
    console.log(inst)
  }, [id])

  const onFinish = async (values: any) => {
    const res = await update(form.getFieldsValue(true))
    setInstitution(res.data)
    message.info('Institution updated')
  }
  return (
    <>
      {inst && (
        <Form
          form={form}
          name="Institution"
          onFinish={onFinish}
          initialValues={inst}
          scrollToFirstError
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please input Name',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: 'Please enter phone number!',
              },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  return !isValidPhoneNumber(value) || value.replace(/\D/g, '').toString().length !== 11
                    ? Promise.reject(new Error('Invalid Phone Number'))
                    : Promise.resolve('')
                },
              }),
            ]}
          >
            <Phone placeholder="Contact Telephone #" />
          </Form.Item>

          <Form.Item
            required
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter your E-mail!',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: 'Please input Address',
              },
            ]}
          >
            <TextArea size="large" />
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
                message: 'Please input City',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="url"
            label="Url"
            rules={[
              {
                required: true,
                message: 'Please input Url',
              },
            ]}
          >
            <Select size="large" placeholder="Select a State" options={statesOptions} />
          </Form.Item>
          <Form.Item
            name="zip"
            label="Zip"
            rules={[
              {
                required: true,
                message: 'Please input Zip',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="url"
            label="Url"
            rules={[
              {
                required: true,
                message: 'Please input Url',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="contact_title"
            label="Contact Title"
            rules={[
              {
                required: true,
                message: 'Please input contact title',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            name="contact_name"
            label="Contact Name"
            rules={[
              {
                required: true,
                message: 'Please enter institution contact name!',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            name="contact_phone"
            label="Contact Phone"
            rules={[
              {
                required: true,
                message: 'Please input contact phone',
              },
            ]}
          >
            <Phone placeholder="Contact Telephone #" />
          </Form.Item>

          <Form.Item
            name="private"
            label="Private College?"
            rules={[
              {
                required: true,
                message: 'Please answer private college  question!',
              },
            ]}
          >
            <Radio.Group
              options={yesNoOptions}
              optionType="button"
              buttonStyle="solid"
              onChange={radioPrivate}
              value={privateValue}
            />
          </Form.Item>

          <Form.Item
            name="enabled"
            label="Enabled?"
            rules={[
              {
                required: true,
                message: 'Please answer enabled question!',
              },
            ]}
          >
            <Radio.Group
              options={yesNoOptions}
              optionType="button"
              buttonStyle="solid"
              onChange={radioEnable}
              value={enableValue}
            />
          </Form.Item>

          <Form.Item name="notes" label="Notes">
            <TextArea size="large" />
          </Form.Item>

          <Button type="primary" style={{ float: 'right' }} htmlType="submit">
            Save
          </Button>
        </Form>
      )}
    </>
  )
}

export default InstitutionEdit
