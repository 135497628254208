import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Renewal } from '../../components/application/renewal'
import { renewalRequest } from '../../services/applications.service'
import { Layout } from 'antd'
import { Application } from '../../models/application.model'
import { isRenewalProcessOpen } from '../../contants/options'
import { Redirect } from 'react-router'

const RenewalScreen = () => {
  const [renewal, setRenewal] = React.useState<Application>(null)
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()

  const uuid = query.get('uuid')

  const getRenewal = async () => {
    const record = await renewalRequest(uuid)
    setRenewal(record.data)
  }
  useEffect(() => {
    getRenewal()
  }, [uuid])

  if (!isRenewalProcessOpen()) return <Redirect to="/" />

  return (
    <Layout>
      <Renewal application={renewal} />
    </Layout>
  )
}

export default RenewalScreen
