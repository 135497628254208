import React, { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'

import Button from '../../components/forms/button.component'
import Text from '../../components/style/text.component'
import { logout, useLogin } from '../../utils/auth.utils'
import { findAll } from '../../services/users.service'
import { Layout, Breadcrumb, Menu, Table } from 'antd'
import { User } from '../../models/user.model'
const { Header, Content, Footer } = Layout
const { SubMenu } = Menu

const UserScreen = () => {
  const history = useHistory()
  const { me, isLogin } = useLogin()

  const [users, setUsers] = React.useState(null)

  const getUsers = async () => {
    const u = await findAll()
    setUsers(u.data)
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => <Link to={`/admin/users/edit/${id}`}>Edit</Link>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Institution',
      dataIndex: ['institution', 'name'],
      key: 'institution',
    },
  ]

  useEffect(() => {
    getUsers()
  }, [1])

  if (!isLogin) return <Redirect to="/" />
  return <>{users && <Table dataSource={users} columns={columns} pagination={false} />}</>
}

export default UserScreen
