export const majorsList = [
  { value: '1', label: 'HEALTH RELATED' },
  { value: '2', label: 'EDUCATION' },
  { value: '3', label: 'BUSINESS' },
  { value: '4', label: 'COMPUTER SCIENCE' },
  { value: '5', label: 'ENGLISH' },
  { value: '7', label: 'PSYCH/SOCIOLOGY' },
  { value: '8', label: 'COMMUNICATIONS' },
  { value: '9', label: 'UNDECIDED' },
  { value: '10', label: 'BIOLOGICAL SCIENCE' },
  { value: '13', label: 'ENGINEERING' },
  { value: '14', label: 'FINE ARTS' },
  { value: '15', label: 'JOURNALISM' },
  { value: '16', label: 'CRIMINAL JUSTICE' },
  { value: '17', label: 'INTERDISCIPLINARY STUDIES' },
  { value: '18', label: 'ADMINISTRATION' },
  { value: '19', label: 'SOCIAL WORK' },
  { value: '21', label: 'HISTORY' },
  { value: '22', label: 'FOREIGN LANGUAGE' },
  { value: '23', label: 'RELIGION' },
  { value: '24', label: 'SOCIOLOGY' },
  { value: '25', label: 'PSYCHOLOGY' },
  { value: '26', label: 'MATH' },
  { value: '27', label: 'POLITICAL SCIENCE' },
  { value: '28', label: 'OTHER' },
].sort((a, b) => (a.label > b.label ? 1 : -1))
