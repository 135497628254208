import React, { useEffect } from 'react'
import { report1, report23 } from '../../services/reports.service'
import { useLogin } from '../../utils/auth.utils'
import 'antd/dist/antd.css'
import { Button, Col, Form, Row, Select, Switch } from 'antd'
import { getCurrentYearReports, institutionOptions, yearOptions } from '../../contants/options'
import { useLocation } from 'react-router-dom'

const Report1Screen = () => {
  const { me, isLogin } = useLogin()
  const [isPrivate, setPrivate] = React.useState(!!(me?.institution?.id && me.institution?.id > 3))
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  const [resultSet, setResultSet] = React.useState(null)
  const [year, setYear] = React.useState(getCurrentYearReports())
  const [form] = Form.useForm()

  const handlePrivate = e => {
    setPrivate(e)
  }
  const handleYear = e => {
    setYear(e)
  }
  const handleChange = async () => {
    setResultSet(null)
    const results = await report1(year, isPrivate)
    if (results) {
      setResultSet(results.data)
    }
  }

  useEffect(() => {
    handleChange()
  }, [year, isPrivate])

  return (
    <div className={'center'}>
      <h2>Recipient 1</h2>
      {me?.role === 'admin' && (
        <Form
          form={form}
          name="reports"
          onFinish={handleChange}
          labelCol={{
            span: 4,
            offset: 2,
          }}
          wrapperCol={{
            span: 12,
          }}
          layout="horizontal"
        >
          <Form.Item label="Year">
            <Row>
              <Col span={10}>
                <Form.Item name="year" initialValue={year} required={true}>
                  <Select
                    options={yearOptions().map(x => ({ value: x.value, label: `${x.label} - ${x.label + 1}` }))}
                    onChange={handleYear}
                  />
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item name="isPrivate">
                  <Switch checkedChildren="Private" onChange={handlePrivate} unCheckedChildren="Public" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      )}
      {resultSet && (
        <div>
          <h1>
            {!!isPrivate && <>Private College</>}
            {!isPrivate && <>Regents' Universities</>} Summary Information
          </h1>
          <div className={'ant-table-content'}>
            Based on information by the {!!isPrivate && <>Private College</>}
            {!isPrivate && <>Regents' Universities</>}, the following statistics apply to the{' '}
            <>
              {year} - {year + 1}
            </>{' '}
            Carver Scholar award recipients.
            <table cellPadding={5} className={'antd-table'}>
              <thead className={'antd-table-head'}>
                <tr>
                  <th align={'center'}>UNIVERSITY</th>
                  <th align={'center'}># OF APPLICATIONS</th>
                  <th align={'center'}># OF AWARDS</th>
                  <th align={'center'}>AVG. AWARD</th>
                  <th align={'center'}>AVG. GPA</th>
                  <th align={'center'}>AGE RANGE</th>
                  <th align={'center'}>MAJOR</th>
                </tr>
              </thead>
              {resultSet && resultSet?.general && (
                <tbody>
                  {resultSet.awards.map((data, index) => (
                    <tr key={index}>
                      <td align={'center'}>
                        <>
                          {data.name}
                          <br />
                          {((data.dependents / data.total) * 100).toFixed(2)}% HAVE DEPENDENTS
                        </>
                      </td>
                      <td>{resultSet.general.find(_ => _.id == data.id).total}</td>
                      <td>
                        <>
                          {data.total} <br />
                        </>
                        <>
                          ({data.first_year} - First Year)
                          <br />
                        </>
                        <>({data.renewals} - Renewals )</>
                      </td>
                      <td>{formatter.format(data.avg_awarded)}</td>
                      <td>{data.avg_gpa}</td>
                      <td>
                        {data.min_age} - {data.max_age}
                      </td>
                      <td>
                        {resultSet.majors && (
                          <>
                            {resultSet.majors
                              .filter(_ => _.id == data.id)
                              .map((major, index) => (
                                <li key={index}>
                                  {major.major}:{major.total}
                                </li>
                              ))}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              <tfoot></tfoot>
            </table>
            <h2>Combined Statistics</h2>
            <table className={'antd-table'}>
              <tbody className={'ant-table-body'}>
                <tr>
                  <th className={'ant-table-cell'}>Total First Year Applicants</th>
                  <td className={'ant-table-cell'}>
                    {resultSet.general.reduce((acc, val) => acc + parseFloat(val.first_year), 0)}
                  </td>
                </tr>
                <tr>
                  <th className={'ant-table-cell'}>Total Number of All Awards</th>
                  <td className={'ant-table-cell'}>
                    {resultSet.awards.reduce((acc, val) => acc + parseFloat(val.total), 0)}
                  </td>
                </tr>
                <tr>
                  <th className={'ant-table-cell'}>Total Dollar Amount Awarded</th>
                  <td className={'ant-table-cell'}>
                    {formatter.format(resultSet.awards.reduce((acc, val) => acc + parseFloat(val.amount_awarded), 0))}
                  </td>
                </tr>
                <tr>
                  <th className={'ant-table-cell'}>Average GPA (Awarded)</th>
                  <td className={'ant-table-cell'}>
                    {(
                      resultSet.awards.reduce((acc, val) => acc + parseFloat(val.avg_gpa), 0.0) /
                      resultSet.awards.length
                    ).toFixed(3)}
                  </td>
                </tr>
                <tr>
                  <th className={'ant-table-cell'}>Average Age (Awarded)</th>
                  <td className={'ant-table-cell'}>
                    {(
                      resultSet.awards.reduce((acc, val) => acc + parseFloat(val.avg_age), 0) / resultSet.awards.length
                    ).toFixed()}
                  </td>
                </tr>
                <tr>
                  <th>Marital Status (Awarded)</th>
                  <td>
                    <>{resultSet.awards.reduce((acc, val) => acc + Number(val.married), 0)} </>
                    Married / <>{resultSet.awards.reduce((acc, val) => acc + Number(val.single), 0)}</> Single
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}
export default Report1Screen
