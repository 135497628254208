import React, { useState } from 'react'
import { Form, Input, DatePicker, Row, Col, Button, Space, Select } from 'antd'
import 'antd/dist/antd.css'
import { MinusSquareOutlined, HistoryOutlined, PlusOutlined } from '@ant-design/icons'
import { Phone } from './phone'
import { isValidPhoneNumber } from 'react-phone-number-input'
import moment from 'moment'
import { statesOptions } from '../../contants/options'

export const Employments = ({ form }) => {
  return (
    <Form.List name="employments">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }, index) => (
            <>
              <h4 style={{ margin: '5px 0 10px 5px', fontWeight: 600 }}>Employer #{index + 1}</h4>
              <Form.Item>
                <Row>
                  <Col span={10}>
                    <Form.Item
                      label="Employer"
                      name={[name, 'employer']}
                      fieldKey={[fieldKey, 'employer']}
                      rules={[{ required: true, message: 'Missing Employer Name' }]}
                    >
                      <Input size={'large'} placeholder={'Employer'} />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item
                      {...restField}
                      label="Title"
                      name={[name, 'title']}
                      fieldKey={[fieldKey, 'title']}
                      rules={[{ required: true, message: 'title' }]}
                    >
                      <Input size="large" placeholder="Title" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      {...restField}
                      label="Hours"
                      name={[name, 'hours']}
                      fieldKey={[fieldKey, 'hours']}
                      rules={[{ required: true, message: 'hours' }]}
                    >
                      <Input type="number" size="large" placeholder="Hours Per Week" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                name={[name, 'address']}
                fieldKey={[fieldKey, 'address']}
                rules={[{ required: true, message: 'Missing address' }]}
              >
                <Input size="large" placeholder="Address" />
              </Form.Item>
              <Form.Item>
                <Row gutter={2}>
                  <Col span={11}>
                    <Form.Item
                      {...restField}
                      name={[name, 'city']}
                      fieldKey={[fieldKey, 'city']}
                      rules={[{ required: true, message: 'Missing city' }]}
                    >
                      <Input size="large" placeholder="City" />
                    </Form.Item>
                  </Col>
                  <Col span={6} offset={1}>
                    <Form.Item
                      {...restField}
                      name={[name, 'state']}
                      fieldKey={[fieldKey, 'state']}
                      rules={[{ required: true, message: 'Missing state' }]}
                    >
                      <Select size="large" placeholder="Select a State" options={statesOptions} />
                    </Form.Item>
                  </Col>
                  <Col span={3} offset={1}>
                    <Form.Item
                      {...restField}
                      name={[name, 'zip']}
                      fieldKey={[fieldKey, 'zip']}
                      rules={[{ required: true, message: 'Missing zip' }]}
                    >
                      <Input size="large" placeholder="Zip" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'phone']}
                      fieldKey={[fieldKey, 'phone']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter contact phone number!',
                        },
                        ({ getFieldValue }) => ({
                          validator: (_, value) => {
                            return !isValidPhoneNumber(value) || value.replace(/\D/g, '').toString().length !== 11
                              ? Promise.reject(new Error('Invalid Phone Number'))
                              : Promise.resolve('')
                          },
                        }),
                      ]}
                    >
                      <Phone placeholder="Contact Telephone #" />
                    </Form.Item>
                  </Col>

                  <Col span={7} offset={1}>
                    <DatePicker
                      size={'large'}
                      placeholder={'Employed From:'}
                      defaultValue={
                        form && form.getFieldValue(['employments', index, 'date_from'])
                          ? moment(form.getFieldValue(['employments', index, 'date_from']))
                          : null
                      }
                      onChange={(value, updated) => {
                        const emp = form.getFieldValue('employments')
                        const ue = emp.map((e, i) => {
                          if (i === index) {
                            return {
                              ...e,
                              date_from: updated,
                            }
                          } else {
                            return { ...e }
                          }
                          return emp
                        })
                        form.setFieldsValue({ employments: ue })
                      }}
                    />
                  </Col>
                  <Col span={7}>
                    <DatePicker
                      placeholder={'Employed To:'}
                      defaultValue={
                        form && form.getFieldValue(['employments', index, 'date_to'])
                          ? moment(form.getFieldValue(['employments', index, 'date_to']))
                          : null
                      }
                      size={'large'}
                      onChange={(value, updated) => {
                        const emp = form.getFieldValue('employments')
                        const ue = emp.map((e, i) => {
                          if (i === index) {
                            return {
                              ...e,
                              date_to: updated,
                            }
                          } else {
                            return { ...e }
                          }
                          return emp
                        })
                        form.setFieldsValue({ employments: ue })
                      }}
                    />
                  </Col>
                  <Col span={1}>
                    <MinusSquareOutlined style={{ float: 'right', margin: '10px 0' }} onClick={() => remove(name)} />
                  </Col>
                </Row>
              </Form.Item>
            </>
          ))}
          {fields.length < 2 && (
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Employment History <HistoryOutlined />
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  )
}
