import React from 'react'
import { report23 } from '../../services/reports.service'
import { useLocation } from 'react-router-dom'
import { useLogin } from '../../utils/auth.utils'
import { getCurrentYearReports, institutionOptions, yearOptions } from '../../contants/options'
import { Button, Form, Input, Select, Radio, message, Col, Row, Table, Switch } from 'antd'
import { majorsList } from '../../contants/majors'

const Report23Screen = () => {
  const [renewal, setRenewal] = React.useState(0)
  const [resultSet, setResultSet] = React.useState(null)
  const [year, setYear] = React.useState(getCurrentYearReports())
  const [institution, setInstitution] = React.useState(null)
  const { me, isLogin } = useLogin()

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const handleChange = async () => {
    const instId = me.institution?.id || (institution ? Number.parseInt(institution) : '')
    //if (instId && year) {
    const results = await report23(year, instId || null, renewal)
    setResultSet(results.data)
    //}
  }
  const handleInstitution = e => {
    setInstitution(e)
    setResultSet(null)
  }
  const handleRenewal = e => {
    setRenewal(e ? 1 : 0)
    setResultSet(null)
  }
  const handleYear = e => {
    setYear(e)
    setResultSet(null)
  }

  console.log(yearOptions())

  //  if (!isLogin) return <Redirect to="/" />
  const [form] = Form.useForm()
  const columns = [
    {
      title: 'Applicant Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Comm College Transfer',
      key: 'transfer',
      dataIndex: 'transfer',
      render: value => {
        // do something like adding commas to the value or prefix
        return <span>{value ? 'Yes' : 'No'}</span>
      },
    },
    {
      title: 'Major',
      key: 'major',
      dataIndex: 'major',
      render: value => {
        const major = majorsList.find(x => x.value === value)
        // do something like adding commas to the value or prefix
        return <span>{major ? major.label : ''} </span>
      },
    },
    {
      title: 'Married?',
      key: 'married',
      dataIndex: 'married',
      render: value => {
        // do something like adding commas to the value or prefix
        return <span>{value ? 'Yes' : 'No'}</span>
      },
    },
    {
      title: 'Age',
      key: 'age',
      dataIndex: 'age',
    },
    {
      title: 'Cumulative GPA',
      key: 'gpa',
      dataIndex: 'gpa',
    },
    {
      title: '# of Dependents',
      key: 'dependents',
      dataIndex: 'dependents',
    },
    {
      title: 'Financial Aid Needed',
      key: 'amount_requested',
      dataIndex: 'amount_requested',
      render: (value, row, index) => {
        // do something like adding commas to the value or prefix
        return <span>{value ? `$ ${value.toLocaleString('en-US')}` : ''}</span>
      },
    },
    {
      title: 'Financial Aid Award',
      key: 'amount_awarded',
      dataIndex: 'amount_awarded',
      render: (value, row, index) => {
        // do something like adding commas to the value or prefix
        return <span>{value ? `$ ${value.toLocaleString('en-US')}` : ''}</span>
      },
    },
    {
      title: 'Carver Scholars Award',
      key: 'amount_actual',
      dataIndex: 'amount_actual',
      render: (value, row, index) => {
        // do something like adding commas to the value or prefix
        return <span>{value ? `$ ${value.toLocaleString('en-US')}` : ''}</span>
      },
    },
  ]
  return (
    <div className={'center'}>
      <h2>Recipient 2 & 3</h2>
      <Form
        form={form}
        name="reports"
        onFinish={handleChange}
        labelCol={{
          span: 4,
          offset: 2,
        }}
        wrapperCol={{
          span: 12,
        }}
        layout="horizontal"
      >
        {me.role != 'institution' && (
          <Form.Item name="institution" label="Institution" required={true}>
            <Select options={institutionOptions} onChange={handleInstitution} allowClear={true} />
          </Form.Item>
        )}
        <Form.Item label="Year">
          <Row>
            <Col span={10}>
              <Form.Item name="year" required={true}>
                <Select
                  defaultValue={year}
                  options={yearOptions().map(x => ({ value: x.value, label: `${x.label} - ${x.label + 1}` }))}
                  onChange={handleYear}
                  allowClear={me.role != 'institution'}
                />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item name="renewal">
                <Switch checkedChildren="Renewal" onChange={handleRenewal} unCheckedChildren="First Year" />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Button type={'primary'} disabled={!institution || !year} onClick={handleChange}>
                Search
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      {resultSet && (
        <>
          <h1>{institutionOptions.find(x => x.value == institution).label} </h1>
          <p>{renewal ? 'RENEWAL' : 'FIRST-YEAR'} DETAILS REPORT</p>
          <p>{`${year} - ${year + 1} Academic Year`}</p>
          <Table columns={columns} dataSource={resultSet} pagination={false} />
        </>
      )}
    </div>
  )
}
export default Report23Screen
