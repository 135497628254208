import React, { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router'
import { login, useLogin } from '../../utils/auth.utils'
import { Form, Input, Button, Checkbox, message, Result } from 'antd'
import { forgot, reset, validate } from '../../services/auth.service'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

const ForgotScreen = () => {
  const history = useHistory()
  const { isLogin } = useLogin()
  const [forgotSent, setForgotSent] = React.useState(false)
  const [resetSent, setResetSent] = React.useState(false)
  const [forgotStart, setForgotStart] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [verifiedToken, setVerifiedToken] = React.useState(false)
  const [form] = Form.useForm()

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()

  const token = query.get('token')

  const onForgot = async () => {
    setLoading(true)
    await forgot(form.getFieldValue('email'))
    setForgotSent(true)
    setLoading(false)
  }

  const validateToken = async (token: any) => {
    setLoading(true)
    const res = await validate(token)
    if (res.data.success) {
      setVerifiedToken(token)
    } else {
      setVerifiedToken(false)
    }
    setLoading(false)
  }
  const onFinish = async (values: any) => {
    setLoading(true)
    const user = form.getFieldsValue(true)
    user.reset_token = token
    const res = await reset(user)
    setResetSent(true)
    setLoading(false)
  }
  useEffect(() => {
    if (token) {
      validateToken(token)
    } else {
      setForgotStart(true)
    }
  }, [token])

  if (isLogin) return <Redirect to="/connected" />

  return (
    <>
      {forgotSent && (
        <Result
          style={{
            width: 800,
            margin: 'auto',
            padding: '25px',
          }}
          status="success"
          title="Your password request was submitted"
          subTitle={`If you have an account you will receive an email on how to reset your password`}
        ></Result>
      )}

      {!forgotSent && !forgotStart && verifiedToken === false && (
        <>
          <h1>Forgot Password Request</h1>
          <Result
            style={{
              width: 800,
              margin: 'auto',
              padding: '25px',
            }}
            status="error"
            title="Invalid Link"
            subTitle={`please verify your email link and try again`}
          ></Result>
        </>
      )}

      {!forgotSent && forgotStart && (
        <>
          <h1>Forgot Password Request</h1>
          <Form
            onFinish={onForgot}
            name="forgot"
            form={form}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Request Password Reset
              </Button>
            </Form.Item>
          </Form>
        </>
      )}

      {!forgotSent && verifiedToken && (
        <>
          {resetSent && (
            <Result
              style={{
                width: 800,
                margin: 'auto',
                padding: '25px',
              }}
              status="success"
              title="Your password has been reset"
              subTitle={`you can now login with your new password`}
            >
              <div className="desc">
                <p>
                  Click <Link to={'/login'}>here</Link> to return to login
                </p>
              </div>
            </Result>
          )}
          {!resetSent && (
            <>
              <h1>Reset Password</h1>
              <p>Please enter a new password.</p>
              <Form
                form={form}
                name="reset"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your new password!',
                    },
                  ]}
                >
                  <Input type={'password'} />
                </Form.Item>

                <Form.Item
                  label="Confirm"
                  name="confirm"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator: async (_, value) => {
                        if (value && getFieldValue('password') !== value) {
                          new Error('The two passwords must match!')
                        }
                      },
                    }),
                  ]}
                >
                  <Input type={'password'} />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Request Password Reset
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ForgotScreen
