import React, { useEffect } from 'react'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'

import { Form, Radio, Row, Col, Select, Input, Space, Button } from 'antd'
import { findAll } from '../../services/institutions.service'
import { yesNoOptions, statusOptions, yearOptions, dependentsOptions } from '../../contants/options'
import { useLogin } from '../../utils/auth.utils'

function ApplicationFilters(props) {
  const [form] = Form.useForm()
  const [expand, setExpand] = React.useState(false)
  const [institutions, setInstitutions] = React.useState(null)

  const toggle = () => {
    setExpand(!expand)
  }

  const getInstitutions = async () => {
    const u = await findAll()
    setInstitutions(
      u.data.map(x => {
        return { label: x.name, value: x.id }
      }),
    )
  }
  const onReset = async () => {
    await props.handleReset()
    form.resetFields()
    console.log(props.filters)
  }
  useEffect(() => {
    getInstitutions()
  }, [])
  const { me, isLogin } = useLogin()
  const selectOptions = yearOptions().map(x => ({ value: x.value, label: `${x.label} - ${x.label + 1}` }))
  return (
    <Form
      className="ant-advanced-search-form"
      initialValues={props.filters}
      name="filters"
      form={form}
      onFinish={() => {
        props.searchCallback(form.getFieldsValue())
      }}
    >
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item name="search">
            <Input placeholder="Search" />
          </Form.Item>
        </Col>
        {me.role === 'admin' && (
          <Col span={4}>
            <Form.Item name="institution">
              <Select allowClear={true} options={institutions} placeholder="Institution" />
            </Form.Item>
          </Col>
        )}
        <Col span={3}>
          <Form.Item name="years">
            <Select allowClear={true} options={selectOptions} placeholder="Years" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="status">
            <Select allowClear={true} options={statusOptions.filter(x => x.value != 'draft')} placeholder="Status" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="renewal">
            <Select allowClear={true} options={yesNoOptions} placeholder="Renewal" />
          </Form.Item>
        </Col>
        <Col span={3} style={{ display: expand ? 'block' : 'none' }}>
          <Form.Item name="dependents">
            <Select allowClear={true} options={dependentsOptions} placeholder="Dependents" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>

          <Button type="default" onClick={onReset} htmlType="button">
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default ApplicationFilters
