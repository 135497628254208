import React, { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import Button from '../../components/forms/button.component'
import Text from '../../components/style/text.component'
import { logout, useLogin } from '../../utils/auth.utils'
import { findAll } from '../../services/institutions.service'
import { Layout, Breadcrumb, Menu, Table } from 'antd'
import { Institution } from '../../models/institution.model'
import PhoneNumber from 'awesome-phonenumber'

const { Header, Content, Footer } = Layout
const { SubMenu } = Menu

const InstitutionScreen = () => {
  const history = useHistory()
  const { me, isLogin } = useLogin()

  const [institutions, setInstitutions] = React.useState(null)

  const getInstitutions = async () => {
    const u = await findAll()
    setInstitutions(u.data)
  }
  const columns = [
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => <Link to={`/admin/institutions/edit/${id}`}>Edit</Link>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact_name',
      render: (name: string, record: Institution) => <a href={`mailto:${record.email}`}>{record.contact_name}</a>,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone: string) => {
        if (!phone) {
          return ''
        }
        return PhoneNumber(phone, 'US').getNumber('national')
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ]

  useEffect(() => {
    getInstitutions()
  }, [1])

  if (!isLogin) return <Redirect to="/" />

  return <>{institutions && <Table dataSource={institutions} columns={columns} pagination={false} />}</>
}

export default InstitutionScreen
