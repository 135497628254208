import React from 'react'
import { Redirect, useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import TextInput from '../../components/forms/text.input'
import { validateEmail } from '../../utils/regex.util'
import { signin } from '../../services/auth.service'
import { login, useLogin } from '../../utils/auth.utils'
import { Form, Input, Button, Checkbox, message } from 'antd'

const SigninScreen = () => {
  const history = useHistory()
  const { isLogin } = useLogin()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errorMsg, setErrorMsg] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const submit = async () => {
    if (!validateEmail(email)) {
      setErrorMsg('Credentials incorrect')
      return
    }

    setLoading(true)
    const res = await signin(email, password)
    setLoading(false)

    if (res.statusCode === 200) {
      login(res.data.token)
      history.push('/admin/')
    } else {
      setErrorMsg('Credentials incorrect')
      message.error(errorMsg)
    }
  }

  if (isLogin) return <Redirect to="/connected" />

  return (
    <>
      <h1>Login</h1>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input onChange={e => setEmail(e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password onChange={e => setPassword(e.target.value)} />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Link to={'/forgot'}>Forgot your password?</Link>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" onClick={submit}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default SigninScreen
