import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Result, Button } from 'antd';

import colors from '../../themes/colors.theme';

const NotFoundScreen = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Link to={'/'}><Button type="primary">Back Home</Button></Link>}
    />
  );
}

export default NotFoundScreen;