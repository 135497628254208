import { Notification } from '../models/notification.model'
import { post } from './utils.service'
import { APIResponse } from '../models/api.model'

export const sendReminder = async (record): Promise<APIResponse<Notification>> => {
  const res = await post(`/applications/notifications/send/reminder`, record)
  return await res.json()
}

export const sendRenewal = async (record): Promise<APIResponse<Notification>> => {
  const res = await post(`/applications/notifications/send/renewal`, record)
  return await res.json()
}

export const generateRenewal = async (record): Promise<APIResponse<Notification>> => {
  const res = await post(`/applications/notifications/generate/renewal`, record)
  return await res.json()
}

export const sendIncomplete = async (record): Promise<APIResponse<Notification>> => {
  const res = await post(`/applications/notifications/send/incomplete`, record)
  return await res.json()
}
