import React, { useState, useEffect } from 'react'
import 'antd/dist/antd.css'
import { Input, Form, Select, InputNumber, Radio } from 'antd'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Phone } from './phone'
import { dependentsOptions, yesNoOptions } from '../../contants/options'
import { Addresses } from './addresses'
import { Employments } from './employments'
import { findAll } from '../../services/institutions.service'
import { majorsList } from '../../contants/majors'

export const Main = (props: any) => {
  const [marriedValue, setMarriedValue] = React.useState(props?.application?.married)
  const radioMarried = (e: any) => {
    setMarriedValue(e.target.value)
  }

  const [transferValue, setTransferValue] = React.useState(props?.application?.transfer)
  const radioTransfer = (e: any) => {
    setTransferValue(e.target.value)
  }

  const [dependentsValue, setDependentsValue] = React.useState(props?.application?.dependents)
  const radioDependents = (e: any) => {
    setDependentsValue(e.target.value)
  }

  const [institutions, setInstitutions] = React.useState(null)
  const getInstitutions = async () => {
    const u = await findAll()
    setInstitutions(
      u.data.map(x => {
        return { label: x.name, value: x.id }
      }),
    )
  }

  useEffect(() => {
    getInstitutions()
  }, [])

  return (
    <>
      {props.renewal && (
        <>
          <Form.Item label="Institution">{props.application.institution.name}</Form.Item>
          <Form.Item label="Name">
            {props.application.firstname} {props.application.middlename} {props.application.lastname}
          </Form.Item>
        </>
      )}
      {!props.renewal && (
        <>
          <Form.Item
            name="institution"
            label="Institution"
            rules={[
              {
                required: true,
                message: 'Please enter the institution you will be applying for.',
              },
            ]}
          >
            <Select size="large" placeholder="Select Your Institution" options={institutions} />
          </Form.Item>

          <Form.Item
            name="digits"
            label="SSN"
            rules={[
              {
                required: true,
                message: 'Please enter last 4 digits of your SSN',
              },
              {
                max: 4,
                message: 'Please only enter the last 4 digits of your SSN',
              },
              {
                min: 4,
                message: 'Please enter the last 4 digits of your SSN',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item label="Name" style={{ marginBottom: 0 }}>
            <Form.Item
              name="firstname"
              label="First Name"
              style={{ display: 'inline-block', width: 'calc(35% )', margin: '0 8px 0 0' }}
              rules={[
                {
                  required: true,
                  message: 'Please input your firstname!',
                  whitespace: true,
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              name="middlename"
              label="Middle Name"
              style={{ display: 'inline-block', width: 'calc(25% )', margin: '0 8px' }}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              name="lastname"
              label="Last Name"
              style={{ display: 'inline-block', width: 'calc(35%)' }}
              rules={[
                {
                  required: true,
                  message: 'Please enter your last name!',
                  whitespace: true,
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Form.Item>
        </>
      )}
      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[
          {
            required: true,
            message: 'Please enter your phone number!',
          },
          ({ getFieldValue }) => ({
            validator: (_, value) => {
              return !isValidPhoneNumber(value) || value.replace(/\D/g, '').toString().length !== 11
                ? Promise.reject(new Error('Invalid Phone Number'))
                : Promise.resolve('')
            },
          }),
        ]}
      >
        <Phone placeholder="Contact Telephone #" />
      </Form.Item>
      <Form.Item
        required
        name="email"
        label="E-mail"
        hasFeedback
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please enter your E-mail!',
          },
        ]}
      >
        <Input size="large" />
      </Form.Item>
      {props.new && (
        <Form.Item
          name="confirm"
          label="Confirm Email"
          dependencies={['email']}
          hasFeedback
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Please confirm your email!',
            },
            ({ getFieldValue }) => ({
              validator: async (_, value) => {
                if (!value || getFieldValue('email') === value) {
                  return await Promise.resolve()
                }
                return await Promise.reject(new Error('The two emails that you entered do not match!'))
              },
            }),
          ]}
        >
          <Input size="large" />
        </Form.Item>
      )}
      <Form.Item
        name="age"
        label="Age"
        rules={[
          {
            required: true,
            message: 'Please select your Age!',
          },
        ]}
      >
        <InputNumber max={100} />
      </Form.Item>

      <Form.Item
        name="gpa"
        label="GPA"
        rules={[
          {
            required: true,
            message: 'Please enter your GPA!',
          },
        ]}
      >
        <InputNumber min={0} precision={3} step={0.1} max={5} />
      </Form.Item>

      <Form.Item
        name="major"
        label="Major"
        rules={[
          {
            required: true,
            message: 'Please enter your Major!',
          },
        ]}
      >
        <Select showSearch optionFilterProp="label" size="large" placeholder="Select Your Major" options={majorsList} />
      </Form.Item>
      {!props.renewal && (
        <Form.Item label="Community College Transfer?">
          <Form.Item
            name="transfer"
            style={{ display: 'inline-block', width: 'calc(25% )', margin: '0 4px 0 0' }}
            rules={[
              {
                required: true,
                message: 'Please answer community college transfer question!',
              },
            ]}
          >
            <Radio.Group
              options={yesNoOptions}
              onChange={radioTransfer}
              value={transferValue}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {transferValue > 0 && (
            <>
              <p style={{ display: 'inline-block', width: 'calc(35% )', margin: '5px' }}>
                <>Community College Attending?</>
              </p>
              <Form.Item
                name="attending"
                style={{ display: 'inline-block', width: 'calc(25% )', margin: '0 4px 0 0' }}
                rules={[
                  {
                    required: true,
                    message:
                      'Please answer community college attending question since you marked yourself as a transfer.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}
        </Form.Item>
      )}
      <Form.Item
        name="married"
        label="Married?"
        rules={[
          {
            required: true,
            message: 'Please answer married question!',
          },
        ]}
      >
        <Radio.Group
          options={yesNoOptions}
          optionType="button"
          buttonStyle="solid"
          value={marriedValue}
          onChange={radioMarried}
        />
      </Form.Item>

      <Form.Item
        name="dependents"
        label="Number of Dependents?"
        rules={[
          {
            required: true,
            message: 'Please enter a selection for # of dependents',
          },
        ]}
      >
        <Radio.Group
          options={dependentsOptions}
          optionType="button"
          buttonStyle="solid"
          onChange={radioDependents}
          value={dependentsValue}
        />
      </Form.Item>

      <Form.Item label="Addresses" style={{ marginBottom: 20 }}>
        <Addresses form={props.form} />
      </Form.Item>

      <Form.Item label="Employment History" style={{ marginBottom: 20 }}>
        <Employments form={props.form} />
      </Form.Item>
    </>
  )
}
