import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Breadcrumb, Menu, Table, Row } from 'antd'
import { store } from '../../store/configure.store'
import '../../App.css'
import MeSlice from '../../store/slices/me.slice'
import { refreshToken } from '../../services/auth.service'

const { Header, Content, Footer } = Layout
const { SubMenu } = Menu

const DefaultLayout = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(null)

  const me = async () => {
    const res = await refreshToken()
    if (res.statusCode === 200) {
      localStorage.setItem('token', res.data.token)
      store.dispatch(MeSlice.actions.setMe(res.data.me))
      setCurrentUser(res.data.me)
    } else {
      localStorage.removeItem('token')
    }
  }

  React.useEffect(() => {
    document.title = 'Carver Scholars Program'
    me()
  }, [1])

  return (
    <Layout>
      <Content style={{ padding: '0 50px' }}>
        <Row justify="center" align="top">
          <img width="280" alt={'Logo'} src={'assets/imgs/logo.png'} />
        </Row>
        <div className="site-layout-content">{children}</div>
      </Content>
      <Footer style={{ textAlign: 'center' }}></Footer>
    </Layout>
  )
}

export default DefaultLayout
