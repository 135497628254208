import React, { useEffect, useState, useRef } from 'react'
import { Redirect, useHistory } from 'react-router'
import { findAll, findById, generateRenewal } from '../../services/applications.service'
import {
  Layout,
  Breadcrumb,
  Menu,
  Table,
  Space,
  Button,
  Drawer,
  Badge,
  message,
  Card,
  Modal,
  Input,
  Tooltip,
} from 'antd'
import moment from 'moment'
import { Application } from '../../models/application.model'
import { Review } from '../../models/review.model'
import { Link } from 'react-router-dom'
import ApplicationFilters from './application.filters'
import { MailOutlined, EditOutlined, FileSearchOutlined, PrinterOutlined } from '@ant-design/icons'
import { Reviews } from '../../components/application/reviews'
import {
  reviewStatusOptions,
  statusOptions,
  getYearFromSubmissionDate,
  isApplicationProcessOpen,
  isRenewalProcessOpen,
  isReferenceProcessOpen,
} from '../../contants/options'
import { sendReminder, sendRenewal } from '../../services/notifications.service'
import { Notification } from '../../models/notification.model'
import ApplicationPrint from './application.print'
import ReactToPrint from 'react-to-print'

const ApplicationScreen = () => {
  const history = useHistory()

  const getFilters = () => {
    const localFilter = localStorage.getItem('filters')
    return localFilter ? JSON.parse(localFilter) : { years: year + (month >= 6 ? 1 : 0) }
  }

  const year = new Date().getFullYear()
  const month = new Date().getMonth()
  const [review, setReview] = React.useState(null)
  const [applications, setApplications] = React.useState(null)
  const [refreshApplications, setRefreshApplications] = React.useState(false)
  const [pageNumber, setPageNumber] = React.useState(1)
  const [currentRecord, setCurrentRecord] = React.useState(null)
  const [recordTotal, setRecordTotal] = React.useState(0)
  const [filters, setFilters] = React.useState(getFilters)
  const [sorter, setSorter] = React.useState({})

  const [visibleNotifications, setVisibleNotifications] = useState(false)
  const [visibleReviews, setVisibleReviews] = useState(false)

  const getApplications = async (skip = 0, take = 25) => {
    const c = await findAll(skip ? skip - 1 : skip, take, filters, sorter)
    if (c.statusCode != 500) {
      if (c.data[1]) {
        setRecordTotal(c.data[1])
      }
      setApplications(c.data[0])
    }
  }

  const getApplication = async () => {
    if (currentRecord) {
      const c = await findById(currentRecord.id)
      setCurrentRecord(c.data)
    }
  }

  const getLastNotification = records => {
    if (!records || records.length === 0) {
      return null
    }
    const maxrec = records.reduce((prev, current) =>
      !current.created || prev.created > current.created ? prev : current,
    )
    return maxrec.created
  }

  const dateEqualsToday = compare => {
    return new Date(compare).toLocaleString().substring(0, 10) === new Date().toLocaleString().substring(0, 10)
  }

  const showDrawerReviews = record => {
    setCurrentRecord(record)
    setReview(null)
    setVisibleReviews(true)
  }

  const onCloseReviews = () => {
    setVisibleReviews(false)
    setReview(null)
  }

  const onCloseReview = () => {
    setReview(null)
  }

  const onSendReminder = async () => {
    await sendReminder(currentRecord)
    setRefreshApplications(!refreshApplications)
    message.info('Reminder sent to references')
    await getApplication()
  }

  const onSendRenewal = async () => {
    await sendRenewal(currentRecord)
    setRefreshApplications(!refreshApplications)
    message.info(`Renewal email sent to ${currentRecord.email}`)
    await getApplication()
  }

  const showDrawerNotifications = record => {
    setCurrentRecord(record)
    setVisibleNotifications(true)
  }

  const onCloseNotifications = () => {
    setVisibleNotifications(false)
  }

  const columns = [
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (id: number, record: Application) => (
        <>
          <Input.Group compact>
            <Tooltip title="Edit">
              <Button>
                <Link title="Edit" to={`/admin/applications/edit/${id}`}>
                  <EditOutlined style={{ margin: 5, fontSize: 14 }} />
                </Link>
              </Button>
            </Tooltip>
            <Tooltip title="Print">
              <Button
                shape="circle"
                onClick={() => {
                  setCurrentRecord(record)
                  setIsPrintVisible(true)
                }}
              >
                <PrinterOutlined style={{ margin: 5, fontSize: 14 }} />
              </Button>
            </Tooltip>
            <Tooltip title="Notifications">
              <Button onClick={() => showDrawerNotifications(record)}>
                <Badge style={{ backgroundColor: '#52c41a' }} count={record.notifications?.length}>
                  <MailOutlined style={{ margin: 5, fontSize: 14 }} />
                </Badge>
              </Button>
            </Tooltip>
            <Tooltip title="Reviews">
              <Button onClick={() => showDrawerReviews(record)}>
                <Badge style={{ backgroundColor: '#52c41a' }} count={record.reviews?.length}>
                  <FileSearchOutlined style={{ margin: 5, fontSize: 14 }} />
                </Badge>
              </Button>
            </Tooltip>
          </Input.Group>
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'lastname',
      sorter: true,
      key: 'lastname',
      render: (name: string, record) => (
        <>
          {`${record.firstname} ${record.lastname}`}
          {record.renewal !== 0 && (
            <>
              <br />
              <a href={`mailto:${record.email}`}>{record.email}</a>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Institutions Name',
      dataIndex: ['institution', 'name'],
      key: 'institution_name',
    },
    {
      title: 'First Year/ Renewal ',
      dataIndex: 'renewal',
      sorter: true,
      key: 'renewal',
      render: (renewal: boolean) => (renewal ? 'Renewal' : 'First Year'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      key: '`status`',
      render: (status: string) => statusOptions.find(x => x.value == status).label,
    },
    {
      title: 'Review Status',
      dataIndex: 'reviews',
      key: 'reviews',
      render: (reviews: Review[]) => {
        if (reviews.length === 0) {
          return <>Not Started</>
        } else {
          const l = reviews.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
          return <>{reviewStatusOptions.find(x => x.value == l[0].status).label}</>
        }
      },
    },
    {
      title: 'Application Date',
      dataIndex: 'submitted',
      sorter: true,
      key: 'submitted',
      render: (date: string) => (date ? moment(date).format('ll') : ''),
    },
  ]

  const columnsReviews = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id: number, record: Review) => (
        <Button type="link" onClick={() => setReview(record)}>
          {' '}
          Edit{' '}
        </Button>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        const l = reviewStatusOptions.find(x => {
          return x.value == status
        })
        return (l ? l.label : '') + ' ' + status
      },
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (date: Date) => moment(date).format('LL'),
    },
  ]

  const columnsNotifications = [
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      render: (to: string, record: Notification) => (
        <div>
          <a href={record?.to}>{record?.to}</a>
          <br />
          {record?.subject}
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (to: string, record: Notification) => (
        <div>
          <Badge status="success" text={record?.purpose} />
          <br />
          <Badge status="processing" text={record?.type} />
        </div>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (date: Date) => moment(date).format('LL'),
    },
  ]
  const handlePagination = async (pagination, filters, sorter) => {
    setPageNumber(pagination.current)
    setSorter({ field: sorter?.field, order: sorter?.order })
    localStorage.setItem('pageSize', pagination.pageSize)
    await getApplications(pagination.current, pagination.pageSize)
    setRefreshApplications(!refreshApplications)
  }

  const handleFilter = async f => {
    setFilters(f)
    localStorage.setItem('filters', JSON.stringify(f))
    setPageNumber(1)
  }

  const resetFilter = () => {
    localStorage.removeItem('filters')
    setFilters(getFilters())
    setPageNumber(1)
    setRefreshApplications(!refreshApplications)
  }

  useEffect(() => {
    getApplications()
  }, [filters, refreshApplications])

  useEffect(() => {
    getApplication()
  }, [refreshApplications])

  const [isPrintVisible, setIsPrintVisible] = useState(false)

  const handleCancel = () => {
    setIsPrintVisible(false)
  }
  const componentRef = useRef()

  return (
    <>
      <ApplicationFilters searchCallback={handleFilter} handleReset={resetFilter} filters={filters} />
      {applications && (
        <>
          {recordTotal} Record(s) Found
          <Table
            size="middle"
            dataSource={applications}
            columns={columns}
            pagination={{
              total: recordTotal,
              pageSize: parseInt(localStorage.getItem('pageSize')) || 25,
              current: pageNumber,
            }}
            onChange={handlePagination}
          />
        </>
      )}
      <Drawer
        width={720}
        destroyOnClose
        placement="right"
        title={`${currentRecord?.firstname} ${currentRecord?.lastname} ${
          currentRecord?.renewal ? 'Renewal' : 'First Year'
        } Reviews`}
        onClose={onCloseReviews}
        visible={visibleReviews}
      >
        {!review && (
          <>
            <Space wrap>
              {currentRecord?.status == 'complete' && (
                <Button type="primary" onClick={() => setReview({ application: currentRecord.id })}>
                  Add
                </Button>
              )}

              {currentRecord?.reviews.find(_ => _.status === 'alternate') && (
                <Button type="primary" onClick={() => generateRenewal(currentRecord.id, true, false)}>
                  Generate Renewal
                </Button>
              )}
            </Space>
            {currentRecord?.status != 'complete' && <p>You can not set reviews until the application is completed.</p>}
            <Table dataSource={currentRecord?.reviews} columns={columnsReviews} />
          </>
        )}
        {review && (
          <>
            <Reviews
              close={onCloseReview}
              application={currentRecord}
              review={review}
              refreshApp={setRefreshApplications}
            />
            <Button type="default" style={{ float: 'right', marginRight: 5 }} onClick={onCloseReview}>
              Close
            </Button>
          </>
        )}
      </Drawer>
      <Drawer
        width={720}
        destroyOnClose
        placement="right"
        title={`${currentRecord?.firstname} ${currentRecord?.lastname} ${
          currentRecord?.renewal ? 'Renewal' : 'First Year'
        } Notifications`}
        onClose={onCloseNotifications}
        visible={visibleNotifications}
      >
        {dateEqualsToday(getLastNotification(currentRecord?.notifications)) && <>Recently Sent</>}
        <>
          <>
            {currentRecord?.status.includes('reference') && isReferenceProcessOpen() && (
              <Button type="primary" style={{ float: 'right' }} onClick={onSendReminder} htmlType="submit">
                Send Reference Reminders
              </Button>
            )}
          </>
          <>
            {!!currentRecord?.renewal && !currentRecord?.submitted && isRenewalProcessOpen() && (
              <Button type="primary" style={{ float: 'right' }} onClick={onSendRenewal} htmlType="submit">
                Send Renewal Email
              </Button>
            )}
          </>
          <Table dataSource={currentRecord?.notifications} columns={columnsNotifications} />
        </>
      </Drawer>

      <Modal
        width={1024}
        destroyOnClose={true}
        title={`Carver Statewide Scholarship Program ${getYearFromSubmissionDate(currentRecord?.submitted)} ${
          currentRecord?.renewal ? 'Renewal' : 'First Year'
        }`}
        visible={isPrintVisible}
        onCancel={handleCancel}
        cancelText={'Close'}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <>
          <ReactToPrint
            trigger={() => (
              <Button type="primary" block>
                <PrinterOutlined />
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
          <div ref={componentRef}>
            <ApplicationPrint
              record={currentRecord}
              title={`Carver Statewide Scholarship Program ${getYearFromSubmissionDate(currentRecord?.submitted)} ${
                currentRecord?.renewal ? 'Renewal' : 'First Year'
              }`}
            />
          </div>
        </>
      </Modal>
    </>
  )
}

export default ApplicationScreen
