import React, { useEffect } from 'react'
import { Form, Input, Result, Button, Typography, Space, Select, InputNumber, Divider, Row, Col } from 'antd'
import 'antd/dist/antd.css'
import { submit } from '../../services/reference.service'
import moment from 'moment'
const { Text } = Typography

const { TextArea } = Input

export const Letter = (props: any) => {
  const [form] = Form.useForm()

  const [submission, setSubmission] = React.useState(null)

  const onFinish = async (values: any) => {
    const res = await submit(form.getFieldsValue(true))
    setSubmission(res)
  }

  const [reference, setReference] = React.useState('')
  const [referenceCount, setReferenceCount] = React.useState(0)

  const onReferenceChange = (e: any) => {
    setReference(e.currentTarget.value)
  }

  useEffect(() => {
    setReferenceCount(reference.split(' ').length)
  }, [reference])

  return (
    <>
      {submission && (
        <Result
          style={{
            width: 800,
            margin: 'auto',
            padding: '25px',
          }}
          status="success"
          title="Your Letter of Support has been submitted!"
          subTitle={`Your letter was submitted on on ${moment(submission.submitted).format('MMMM Do YYYY, h:mm a')}. `}
        >
          <p>You and the applicant will be receiving an e-mail confirming the letter of support submission.</p>
          <p>Thank you for your involvement.</p>
          <p>
            <a href="//www.carvertrust.org">Click here to return to the Carver Trust web site</a>
          </p>
        </Result>
      )}
      {!props.reference && !submission && (
        <Result
          status="error"
          title="Request Failed"
          subTitle={
            'Please check your email and try the link again. Otherwise your letter may ' +
            'have been submitted already.'
          }
        >
          <div className="desc">
            <p>
              <Text strong style={{ fontSize: 16 }} />
            </p>
          </div>
        </Result>
      )}
      {props.reference && !submission && (
        <Form
          form={form}
          name="letter"
          onFinish={onFinish}
          initialValues={props.reference}
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
        >
          <Row justify="center">
            <Col span={18}>
              <h1>Roy J. Carver Statewide Scholarship Program Reference Letter Request</h1>
              <p>
                Please fill in the required information as requested in the fields below. Please cut and paste or type
                the one-page (maximum 250 words) letter of reference into the space provided.
              </p>
              <Divider />
            </Col>
          </Row>
          <Divider orientation="left" />
          <Form.Item
            label="Relationship"
            name={'relation'}
            rules={[{ required: true, message: 'Missing Relationship' }]}
          >
            <Input size={'large'} placeholder={'Relationship with Applicant'} />
          </Form.Item>

          <Form.Item
            label="Years of Acquaintance"
            name={'years'}
            rules={[{ required: true, message: 'Missing Years' }]}
          >
            <InputNumber max={50} />
          </Form.Item>

          <Form.Item
            label="Occupation Title"
            name={'title'}
            rules={[{ required: true, message: 'Missing Occupation' }]}
          >
            <Input size={'large'} placeholder={'Occupation Title'} />
          </Form.Item>
          <Form.Item
            name={'letter'}
            label="Letter of Support"
            rules={[
              { required: true, message: 'Letter Required' },
              () => ({
                validator: async (_, value) => {
                  if (referenceCount > props.max) {
                    return Promise.reject(new Error(`The Letter of Support can not have over ${props.max} words!`))
                  }
                },
              }),
            ]}
          >
            <TextArea style={{ height: 250 }} onChange={onReferenceChange} />
          </Form.Item>
          <p
            style={{
              textAlign: 'center',
              color: referenceCount > props.max ? 'red' : 'black',
            }}
          >
            {referenceCount} / <Space /> {props.max}
          </p>

          <Row justify="center">
            <Col span={6}>
              <Button type="primary" htmlType="submit">
                Submit Letter of Support
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}
